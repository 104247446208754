import { Box, Flex, Text } from "@chakra-ui/react";
import axios from "axios";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import SigninSignup from "../components/Auth/SigninSignup";
import { PaywallModal } from "../components/Modals/PaywallModal";
import ActionsSection from "../components/Stocks/actionsSection";
import AlternativeSection from "../components/Stocks/AlternativeSection";
import CompanyBasicCard from "../components/Stocks/CompanyCard/CompanyBasicCard";
import FAQCard from "../components/Stocks/CompanyCard/FAQCard";
import MonkscoreCard from "../components/Stocks/CompanyCard/MonkscoreCard";
import NegativeSection from "../components/Stocks/negativeSection";
import NeutralSection from "../components/Stocks/neutralSection";
import PositiveSection from "../components/Stocks/positiveSection";
import {
  TooltipModal,
  TooltipModalContext,
} from "../components/Stocks/TooltipModal";
import { useLocalStorage } from "../hooks/useLocalStorage";
import DesktopContent from "../layout/MainContent/DesktopContent";
import LeftStocksSubView from "../subviews/LeftSubView/Stocks";
import RightStocksSubView from "../subviews/RightSubview/Stocks";
import { FinancialInfo, IIncome, IRevenue, IStock } from "../types/Stock";
import { getYahooFullTicker } from "../utils/Stock";

import MobileContent from "../layout/MainContent/MobileContent";
import { useDispatch, useSelector } from "react-redux";
import {
  setStock,
  fetchOneTimeline,
  fetchAllCompanyRatios,
  fetchAllRatios,
  actualActiveChart,
  closeOpenActive,
  fetchAllCompanyScores,
} from "../store/slices/stock";
import { ChartModal } from "../components/Modals/Chart";
import { openModal } from "../store/slices/modal";
import { MODAL_TYPES_ENUM } from "../constants/variables";

interface AlphaVantageData {
  AnalystTargetPrice: string;
  DividendPerShare: string;
  DividendYield: string;
  MarketCapitalization: string;
  SharesOutstanding: string;
}

const Company = () => {
  const navigate = useNavigate();

  const { ticker, exchangeTicker } = useParams();
  const dispatch = useDispatch();
  const activeChartModal = useSelector(actualActiveChart);

  const {
    isOpen: isTooltipModalOpen,
    closeModal: closeTooltipModal,
    modalData: tooltipModalData,
  } = useContext(TooltipModalContext);

  const [isPaywallModalOpen, setIsPaywallModalOpen] = useState(false);

  const [visits, setVisits] = useLocalStorage("visits", 0);
  const [accessToken, setAccessToken] = useLocalStorage("accessToken", "");
  const [stockData, setStockData] = useState({} as IStock);
  const [revenueData, setRevenueData] = useState<IRevenue[]>([]);
  const [incomeData, setIncomeData] = useState<IIncome[]>([]);

  const [performance, setPerformance] = useState<string>("");
  const [stockCurrentPrice, setStockCurrentPrice] = useState<number>(0);
  const [showPositive, setShowPositive] = useState<boolean>(false);
  const [showNeutral, setShowNeutral] = useState<boolean>(false);
  const [showNegative, setShowNegative] = useState<boolean>(false);
  const [alphaData, setAlphaData] = useState({} as AlphaVantageData);
  const [financialInfo, setFinancialInfo] = useState<FinancialInfo>({});
  const [currentMenuIdx, setCurrentMenuIdx] = useState(0);

  const logRef = useRef(false);

  useEffect(() => {
    if (accessToken === "" && visits >= 5) {
      window.scrollTo(0, 0);

      dispatch(openModal({ name: "User", link: "", type: "user" }));
    }

    setVisits((prev) => prev + 1);
  }, [accessToken]);

  useEffect(() => {
    if (accessToken) {
      setIsPaywallModalOpen(false);
    }
  }, [accessToken]);

  const stockDataByAlphaVantage = useCallback(async () => {
    const response = await axios.get(
      `https://www.alphavantage.co/query?function=OVERVIEW&symbol=${ticker}&apikey=RXC25FPY55ORRSFA`
    );

    if (response.status === 200) {
      setAlphaData(response?.data);
    } else {
      console.log(response);
    }
  }, [ticker]);

  const getStockPriceYahoo = useCallback(async () => {
    const financialInfo = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/api/v1/stocks/${getYahooFullTicker(
        ticker,
        exchangeTicker
      )}/financial-info`
    );

    const currentPrice =
      financialInfo?.data?.data?.quoteSummary.result[0].financialData
        .currentPrice.raw;

    setStockCurrentPrice(currentPrice);
    setFinancialInfo(financialInfo?.data?.data?.quoteSummary.result[0]);
  }, [exchangeTicker, ticker]);

  const getStocksData = useCallback(
    async (key: string) => {
      const authorization = accessToken.replace(/['"]+/g, "").trim();
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/api/v1/stocks/${exchangeTicker}:${ticker}`,
          {
            headers: {
              token: authorization,
              authorization: authorization,
            },
          }
        );

        if (response.status === 200) {
          if (!response.data.success && response.data.isAnalysisBlocked) {
            dispatch(
              openModal({
                name: MODAL_TYPES_ENUM.pricing,
                link: `analysis-${response.data.limit}`,
                type: "pricing",
              })
            );

            return;
          }

          setStockData(response.data?.data);
          new Promise(() => {
            dispatch(setStock(response.data?.data) as any);
            dispatch(
              fetchOneTimeline({
                fullTicker: `${exchangeTicker}:${ticker}`,
              }) as any
            );
            dispatch(
              fetchAllCompanyRatios({
                fullTicker: `${exchangeTicker}:${ticker}`,
              }) as any
            );
            dispatch(
              fetchAllCompanyScores({
                fullTicker: `${exchangeTicker}:${ticker}`,
              }) as any
            );
            dispatch(
              fetchAllRatios({
                fullTicker: `${exchangeTicker}:${ticker}`,
              }) as any
            );
          });
        } else {
          console.log(response);
        }
      } catch (error) {
        console.log(error);
      }
    },
    [exchangeTicker, ticker, accessToken]
  );

  const getRevenueData = useCallback(
    async (key: string) => {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/charts/${exchangeTicker}:${ticker}/revenue-ltm`
      );

      if (response.status === 200) {
        setRevenueData(response?.data?.data);
      } else {
        console.log(response);
      }
    },
    [exchangeTicker, ticker]
  );

  const getIncomeData = useCallback(
    async (key: string) => {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/stocks/${exchangeTicker}:${ticker}/income-statement`
      );

      if (response.status === 200) {
        setIncomeData(response?.data?.data);
      } else {
        console.log(response);
      }
    },
    [exchangeTicker, ticker]
  );

  const setUserLog = useCallback(async () => {
    if (!accessToken) return;
    if (logRef.current) return;

    const authorization = accessToken.replace(/['"]+/g, "").trim();

    const resp = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/api/v1/userlog/${exchangeTicker}:${ticker}`,
      {
        date: new Date().toISOString(),
      },
      {
        headers: {
          token: authorization,
          authorization: authorization,
        },
      }
    );

    if (resp.status === 401) {
      dispatch(openModal({ name: "User", link: "", type: "user" }));
    }

    if (resp.status === 200) {
      console.log("User log created");
      logRef.current = true;
    }
  }, [accessToken, logRef]);

  const setPerformanceText = useCallback(() => {
    if (stockData && stockData?.monkScore) {
      stockData?.monkScore >= 80 && setPerformance("extremely likely");
      stockData?.monkScore < 80 &&
        stockData?.monkScore >= 60 &&
        setPerformance("very likely");
      stockData?.monkScore < 60 &&
        stockData?.monkScore >= 40 &&
        setPerformance("likely");
      stockData?.monkScore < 40 &&
        stockData?.monkScore >= 20 &&
        setPerformance("unlikely");
      stockData?.monkScore < 20 && setPerformance("very unlikely");
    }
  }, [stockData]);

  const handleShowSections = useCallback(() => {
    if (stockData) {
      setShowPositive(
        stockData?.valueScore >= 60 ||
          stockData?.growthScore >= 60 ||
          stockData?.profitabilityScore >= 60 ||
          stockData?.transparencyScore >= 60 ||
          stockData?.momentumScore >= 60 ||
          stockData?.healthScore >= 60 ||
          stockData?.retributionScore >= 60
          ? true
          : false
      );
      setShowNeutral(
        (stockData?.valueScore >= 40 && stockData?.valueScore < 60) ||
          (stockData?.growthScore >= 40 && stockData?.growthScore < 60) ||
          (stockData?.profitabilityScore >= 40 &&
            stockData?.profitabilityScore < 60) ||
          (stockData?.transparencyScore >= 40 &&
            stockData?.transparencyScore < 60) ||
          (stockData?.momentumScore >= 40 && stockData?.momentumScore < 60) ||
          (stockData?.healthScore >= 40 && stockData?.healthScore < 60) ||
          (stockData?.retributionScore >= 40 &&
            stockData?.retributionScore < 60)
          ? true
          : false
      );
      setShowNegative(
        stockData?.valueScore < 40 ||
          stockData?.growthScore < 40 ||
          stockData?.profitabilityScore < 40 ||
          stockData?.transparencyScore < 40 ||
          stockData?.momentumScore < 40 ||
          stockData?.healthScore < 40 ||
          stockData?.retributionScore < 40
          ? true
          : false
      );
    }
  }, [stockData]);

  useEffect(() => {
    if (ticker) {
      getStocksData(ticker);
      getRevenueData(ticker);
      getIncomeData(ticker);
      console.log("getStockPriceYahoo");
      getStockPriceYahoo();
      stockDataByAlphaVantage();
    }
  }, [
    ticker,
    getRevenueData,
    getIncomeData,
    getStocksData,
    getStockPriceYahoo,
    stockDataByAlphaVantage,
  ]);

  useEffect(() => {
    if (stockData) {
      setPerformanceText();
      handleShowSections();
      const timer = setTimeout(setUserLog, 3000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [handleShowSections, setPerformanceText, stockData]);

  return (
    <>
      <MobileContent>
        <DesktopContent>
          <LeftStocksSubView
            currentIdx={currentMenuIdx}
            setCurrentIdx={setCurrentMenuIdx}
            compData={stockData}
          />
          <RightStocksSubView
            currentIdx={0}
            compData={stockData}
            financialInfo={financialInfo}
            alphaData={alphaData}
            stockCurrentPrice={stockCurrentPrice}
            performance={performance}
          />
        </DesktopContent>
      </MobileContent>
      <TooltipModal
        isOpen={isTooltipModalOpen}
        onClose={closeTooltipModal}
        {...tooltipModalData}
      />
      <ChartModal
        activeChart={activeChartModal.active as any}
        isOpen={activeChartModal.open}
        onClose={() => dispatch(closeOpenActive())}
        type={activeChartModal.type as any}
      />
      <PaywallModal
        isOpen={isPaywallModalOpen}
        onClose={() => setIsPaywallModalOpen(false)}
      />
    </>
  );

  return (
    <>
      <Flex
        as="main"
        flexDirection="column"
        position="relative"
        height="100vh"
        fontFamily="Poppins"
      >
        <Flex
          bgColor="#0A64BC"
          color="white"
          position="absolute"
          alignItems={{ base: "center" }}
          height="186px"
          width="100%"
          top="0"
          left="0"
          zIndex="-1"
        />
        <Flex
          alignItems={{ base: "center" }}
          height="186px"
          width="100%"
          justifyContent="center"
        >
          <Box
            color="white"
            columnGap={1}
            mt={5}
            width="375px"
            position="relative"
          >
            <Box position="absolute" right={3} top={3} style={{ zIndex: 100 }}>
              <SigninSignup />
            </Box>
            <Text
              fontSize="26px"
              fontWeight=""
              display="inline-block"
              style={{ cursor: "pointer" }}
              pl={4}
              pt={4}
              onClick={() => navigate("/")}
            >
              {"<"}
            </Text>
            <Text
              fontSize="32px"
              fontWeight="500"
              lineHeight="35px"
              textDecorationColor="white"
              pt="4"
              pl={4}
            >
              Stock analysis
            </Text>
            <Box pt="4" pl={4}>
              <Text
                fontSize="14px"
                fontWeight="500"
                lineHeight="20px"
                textDecorationColor="white"
              >
                Overview
              </Text>
              <Box
                bgColor="white"
                height="4px"
                width="20px"
                borderRadius={3}
                mt={1}
                ml={5}
              />
            </Box>
          </Box>
        </Flex>

        <CompanyBasicCard compData={stockData} />

        <Box py={7} className="grid" px={{ base: 3, md: 0 }}>
          <Box w="100%" display="flex" justifyContent="end">
            <MonkscoreCard
              compData={stockData}
              performance={performance}
              sectorId={stockData?.sector}
            />
          </Box>

          {showPositive && (
            <PositiveSection
              fullTicker={`${exchangeTicker}:${ticker}`}
              stockData={stockData && stockData}
            />
          )}

          {showNeutral && (
            <NeutralSection
              fullTicker={`${exchangeTicker}:${ticker}`}
              stockData={stockData && stockData}
            />
          )}

          {showNegative && (
            <NegativeSection
              fullTicker={`${exchangeTicker}:${ticker}`}
              stockData={stockData && stockData}
            />
          )}

          <ActionsSection stock={stockData} />

          <AlternativeSection stockData={stockData} />

          <Box w="100%">
            <FAQCard
              compData={stockData}
              fullTicker={`${exchangeTicker}:${ticker}`}
            />
          </Box>
        </Box>
      </Flex>

      <TooltipModal
        isOpen={isTooltipModalOpen}
        onClose={closeTooltipModal}
        {...tooltipModalData}
      />

      {/* <PaywallModal
        isOpen={isPaywallModalOpen}
        onClose={() => setIsPaywallModalOpen(false)}
      /> */}
    </>
  );
};

export default Company;
