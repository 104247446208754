import {
  Box,
  Button,
  Progress,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from '@chakra-ui/react';
import DesktopContent from '../layout/MainContent/DesktopContent';
import MobileContent from '../layout/MainContent/MobileContent';
import PricingCards from '../components/Modals/PricingModal/pricing-cards';
import { useCallback, useEffect, useState } from 'react';
import { useFetchSubscriptionDataQuery } from '../services/userApi';
import { useFetchPaymentHistoryDataQuery } from '../services/paymentApi';
import { useLocalStorage } from '../hooks/useLocalStorage';
import MembershipCard from '../components/Subscription';
import SubscriptionDetails from '../components/Modals/SubscriptionDetails/SubscriptionDetails';
import { PaymentHistory } from '../components/Table/PaymentHistoryTable';
import { EditPaymentMethod } from '../components/Payment/PaymentMethod';
import { PaymentMethodStripe } from '../components/Payment/PaymentMethodStripe';
import { SkeletonCard } from '../components/common/skeleton/card';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

export default function ProfilePage() {
  const tabs = ['Account', 'Subscription', 'Usage'];
  const [isMonthly, setIsMonthly] = useState(true);
  const [accessToken, setAccessToken] = useLocalStorage('accessToken', '');
  const [currentView, setCurrentView] = useState('subscriptionDetails');
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY || '');
  const {
    isLoading,
    data: subscriptionData,
    refetch,
  } = useFetchSubscriptionDataQuery(accessToken);

  useEffect(() => {
    if (subscriptionData && subscriptionData.isAnnualSubscription) {
      setIsMonthly(false);
    }
  }, [subscriptionData]);

  useEffect(() => {
    refetch();
  }, [accessToken]);

  const handleSignOut = () => {
    localStorage.clear();
    setAccessToken('');
    localStorage.removeItem('accessToken');
    window.location.replace('/');
  };

  useEffect(() => {
    if (
      subscriptionData?.subscriptionPlan === 'free' &&
      currentView !== 'pricingCards'
    ) {
      setCurrentView('pricingCards');
    }
  }, [subscriptionData, currentView]);

  const renderComponent = useCallback(() => {
    switch (currentView) {
      case 'pricingCards':
        return (
          <PricingCards
            isMonthly={isMonthly}
            setIsMonthly={setIsMonthly}
            showFree={true}
          />
        );
      case 'paymentMethod':
        // return isLoading ? <SkeletonCard />: <EditPaymentMethod card={subscriptionData?.card} />;
        return isLoading ? <SkeletonCard />: <Elements stripe={stripePromise}>
        <PaymentMethodStripe card={subscriptionData?.card} />
      </Elements>;
      case 'paymentHistory':
        return isLoading ? <SkeletonCard />: <PaymentHistory data={subscriptionData?.history} />;
      default:
        return (
          <SubscriptionDetails
            onChangePlan={() => setCurrentView('pricingCards')}
            onManagePaymentMethod={() => setCurrentView('paymentMethod')}
            onViewPaymentHistory={() => setCurrentView('paymentHistory')}
            onCancelMembership={() => setCurrentView('membershipCard')}
            subscriptionData={subscriptionData}
          />
        );
    }
  }, [currentView, isMonthly, subscriptionData]);

  const RenderMetrics = ({
    metric,
    defaults = [0, 5],
    title,
  }: {
    title: string;
    metric: string;
    defaults: number[];
  }) => {
    const metricMap = {
      numAnalysis: 'analysis',
      numPortfolio: 'portfolio',
      numWatchlist: 'watchlist',
    };
    const numData =
      subscriptionData && Object.keys(subscriptionData || {}).includes(metric)
        ? subscriptionData[metric]
        : defaults[0];

    const totalData =
      subscriptionData && Object.keys(subscriptionData || {}).includes(metric)
        ? subscriptionData.pricingData[metricMap[metric]]
        : defaults[1];

    return (
      <Box
        display={'flex'}
        flexDirection={'column'}
        gap={'10px'}
        justifyItems={'start'}
      >
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          width={'100%'}
          alignItems={'center'}
        >
          <Text>{title}</Text>

          <Box
            display={'flex'}
            justifyContent={'flex-end'}
            alignItems={'baseline'}
            gap={'10px'}
          >
            {numData}/ {totalData}
          </Box>
        </Box>

        <Progress
          value={totalData !== 0 ? (numData * 100) / totalData : 0}
          max={100}
          size='lg'
          colorScheme='blue'
          width='100%'
          borderRadius={'md'}
        />
      </Box>
    );
  };

  return (
    <MobileContent>
      <DesktopContent>
        <Box display={'flex'} flexDirection={'column'} gap={'20px'}>
          <Text
            fontFamily='Poppins'
            fontStyle='normal'
            fontWeight='600'
            fontSize='28px'
            lineHeight='36px'
            textAlign='left'
            color='#021425'
          >
            Settings
          </Text>
          <Text>
            View about your account, subscription and usage of the platform
          </Text>
          <Tabs
            onChange={() => {
              setCurrentView('subscriptionDetails');
            }}
          >
            <TabList>
              {tabs.map((tab) => (
                <Tab key={tab} value={tab}>
                  {tab}
                </Tab>
              ))}
            </TabList>
            <TabPanels>
              <TabPanel key={'account'} tabIndex={0}>
                <Box
                  display='flex'
                  flexDirection={'column'}
                  gap={'20px'}
                  m='20px'
                >
                  <Text
                    fontFamily='Poppins'
                    fontStyle='normal'
                    fontWeight='600'
                    fontSize='24px'
                    lineHeight='36px'
                    textAlign='left'
                    color='#021425'
                  >
                    Hey {subscriptionData?.user?.firstName} !
                  </Text>
                  <Text fontFamily='Poppins' fontStyle='normal' fontSize={'sm'}>
                    {subscriptionData?.user?.email}
                  </Text>
                  <Button
                    colorScheme='blue'
                    onClick={handleSignOut}
                    w={'100%'}
                    h={'56px'}
                    minH={'56px'}
                    color={'#F9FAFB'}
                    backgroundColor={'#0A64BC'}
                    borderRadius={'8px'}
                    fontSize={'16px'}
                    lineHeight={'24px'}
                    fontWeight={600}
                    fontFamily={'Poppins'}
                  >
                    {'Log out'}
                  </Button>
                  <Button
                    colorScheme='whiteAlpha'
                    onClick={handleSignOut}
                    fontFamily={'Poppins'}
                    w={'100%'}
                    h={'56px'}
                    minH={'56px'}
                    color='#0564B8'
                    backgroundColor={'white'}
                    borderRadius={'8px'}
                    fontSize={'16px'}
                    lineHeight={'24px'}
                    fontWeight={600}
                  >
                    {'Change password'}
                  </Button>
                </Box>
              </TabPanel>
              <TabPanel key={'subscription'} tabIndex={1}>
                <Box
                  display={'flex'}
                  flexDirection={'column'}
                  gap={'20px'}
                  m='20px'
                >
                  <Text
                    fontFamily='Poppins'
                    fontStyle='normal'
                    fontWeight='600'
                    fontSize='18px'
                    lineHeight='36px'
                    textAlign='left'
                    color='#021425'
                  >
                    Manage Your subscription
                  </Text>
                  {renderComponent() }
                  {/* {(subscriptionData?.formattedSubscriptionPlan || 'free') ===
                    'free' ? (
                    renderComponent()
                  ) : (
                    <MembershipCard />
                  )} */}
                </Box>
              </TabPanel>
              <TabPanel key={'usage'} tabIndex={2}>
                <Box
                  display={'flex'}
                  flexDirection={'column'}
                  gap={'20px'}
                  m='20px'
                >
                  <Text
                    fontFamily='Poppins'
                    fontStyle='normal'
                    fontWeight='600'
                    fontSize='18px'
                    lineHeight='36px'
                    textAlign='left'
                    color='#021425'
                  >
                    Your usage of MonkStreet app in this month
                  </Text>

                  <RenderMetrics
                    metric={'numAnalysis'}
                    title={'Stocks Analysed'}
                    defaults={[0, 5]}
                  />
                  <RenderMetrics
                    metric={'numPortfolio'}
                    title={'Stocks in Portfolio'}
                    defaults={[0, 0]}
                  />
                  <RenderMetrics
                    metric={'numWatchlist'}
                    title={'Stocks in Watchlist'}
                    defaults={[0, 0]}
                  />
                </Box>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
      </DesktopContent>
    </MobileContent>
  );
}
