import { Box, Flex, Text, Button } from "@chakra-ui/react";
import { useCallback, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import GrowthBadge from "../../common/badges/GrowthBadge";
import GenericChart from "../GenericChartScores/GenericChart";
import {
  FormatChartNumber,
  handleGrowthBadgeValueDifference,
  handleShareholderTitleSubText,
  handleTitleSubtext,
} from "../../../utils/formatChartNumber";
import { EntryBoxChart } from "../EntryChart/Box";
import CompanyMetaRow from "../../../subviews/RightSubview/Stocks/CompanyMetaRow";
import { useDispatch, useSelector } from "react-redux";
import {
  actualScore,
  actualStock,
  openActiveChart,
} from "../../../store/slices/stock";
import { useScaleCompanyRatio } from "../../../hooks/useData";
import "./MonkScoreChartCard.css";
import { SupportedChartOption, useDataChart } from "../../../hooks/useDataChart";

const ShareholderChartCard = () => {
  
  const { data, getChartSanitizedData: getChartShareholder } = useDataChart(SupportedChartOption.shareholderScoreTimeline);

  const stock = useSelector(actualStock);

  const dispatch = useDispatch();

  const onClickChart = (name: string) => {
    setTimeout(() => {
      dispatch(
        openActiveChart({
          type: "shareholder",
          active: name,
        }) as any
      );
    }, 100);
  };

  const sign = "pts";

  const oneYearMetric = () => {
    if (data) {
      const shareholder = getChartShareholder();
      return Math.round(
        shareholder.length > 0
          ? shareholder[shareholder.length - 1].percentile
          : 0
      );
    }
    return 0;
  };

  const oneYearMetricDifference = useMemo(() => {
    if (data) {
      const shareholder = getChartShareholder();
      return handleGrowthBadgeValueDifference(shareholder,'percentile');
    }
    return 0;
  }, [data, getChartShareholder]);

  const lastUpdate = new Date(stock?.lastScoreUpdateDate || Date.now())
    ?.toLocaleDateString()
    ?.replaceAll("/", "-")
    ?.split("-")
    .reverse()
    .join("-");

  const { chartData } = useScaleCompanyRatio();

  const intl = useIntl();

  return (
    <Box w="100%" h="auto" m="auto" display={"flex"} flexDirection={"column"}>
      <Text as={"p"} fontSize={"14px"} lineHeight={"21px"} fontWeight={700}>
        <FormattedMessage
          id="factors_unique_generic"
          values={{
            name: stock?.companyName ?? "",
            quality: handleShareholderTitleSubText(oneYearMetric())?.toLowerCase(),
          }}
        />{" "}
        <Text as="span" fontSize={"14px"} lineHeight={"21px"} fontWeight={400}>
          compared to its peers.
        </Text>
      </Text>

      <Box bgColor="#F9FAFB" id="ShareholderScoreCard">
        <Box
          position="relative"
          my={"10px"}
          backgroundColor={"#FFF"}
          borderRadius={"20px"}
          pt="20px"
          pb="2px"
          display='flex'
          flexDirection='column'
          gap='20px'
        >
          <Flex flexDirection={'column'} gap="20px" px="20px">
            <Flex flexDirection={'column'}>
              <Flex
                justifyContent={"space-between"}
                alignItems={"flex-start"}
                gap="10px"
              >
                <Text
                  as={"p"}
                  fontSize={"24px"}
                  lineHeight={"36px"}
                  fontWeight={500}
                  display={"flex"}
                  alignItems={"baseline"}
                >
                  {Math.round(oneYearMetric())}<Text as={"p"} fontSize={"12px"}>/100</Text>
                </Text>

                <Button
                  width={"auto"}
                  fontSize={"14px"}
                  lineHeight={"21px"}
                  fontWeight={400}
                  display={"flex"}
                  alignItems={"center"}
                  variant={"unstyled"}
                  p="0"
                  height={"auto"}
                  justifyContent={"flex-end"}
                  flexWrap={"wrap"}
                  wordBreak={"break-all"}
                  textDecoration="none"
                  columnGap={"4px"}
                >
                  <Text display={"flex"} alignItems={"center"}>
                    <FormattedMessage
                      id="evolution"
                      values={{
                        name: "Growth",
                      }}
                    />{" "}
                  </Text>
                </Button>
              </Flex>

              <Box>
                <GrowthBadge
                  vector={oneYearMetricDifference > 0 ? "up" : "down"}
                  meta={`${
                    oneYearMetricDifference > 0 ? "+" : ""
                  }${oneYearMetricDifference.toFixed(2)}${sign} last 1 year`}
                />
              </Box>

              <Box>
                <GenericChart data={getChartShareholder()} />
              </Box>
            </Flex>
          </Flex>

          <Box px="10px">
            {stock && (
              <CompanyMetaRow
                compData={stock}
                lastUpdate={lastUpdate}
                idElementForScreenshot={`ShareholderScoreCard`}
                isChart
                styledBox
              />
            )}
          </Box>
        </Box>
      </Box>
      <Box w="100%" my="10px">
        <Text
          id="message-container"
          as={"p"}
          fontSize={"14px"}
          lineHeight={"21px"}
        >
          <FormattedMessage id="factor_below_prefix" />
          <FormattedMessage
            id="factor_metric_company"
            values={{
              name: stock?.companyName ?? "",
            }}
          >
            {(txt) => <span className="highlight">{txt}</span>}
          </FormattedMessage>
          <FormattedMessage
            id="factor_subfix"
            values={{
              metric_name: intl.formatMessage({
                id: "shareholder_retribution_name",
              }),
            }}
          />
        </Text>
      </Box>

      <Box bgColor="#F9FAFB" id="ShareholderCardRatios">
        <Box
          my={"10px"}
          backgroundColor={"#FFF"}
          borderRadius={"20px"}
          pt="20px"
          pb="2px"
          display={"flex"}
          flexDirection={"column"}
        >
          <Box px="20px">
            <EntryBoxChart
              onClick={onClickChart}
              useValue={false}
              name="Dividend Yield"
              data={chartData("Dividend Yield")}
            />
            <EntryBoxChart
              onClick={onClickChart}
              useValue={false}
              name="Buyback Ratio"
              data={chartData("Buyback Ratio")}
            />
            <EntryBoxChart
              onClick={onClickChart}
              useValue={false}
              name="Debt Paydown Ratio"
              data={chartData("Debt Paydown")}
            />
            <EntryBoxChart
              onClick={onClickChart}
              useValue={false}
              name="Shareholder Yield"
              data={chartData("Shareholder Yield")}
            />
          </Box>

          <Box px="10px">
            {stock && (
              <CompanyMetaRow
                compData={stock}
                lastUpdate={lastUpdate}
                idElementForScreenshot={`ShareholderCardRatios`}
                isChart
                styledBox
              />
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ShareholderChartCard;
