import React from "react";
import { useParams } from "react-router-dom";
import { Box, Flex, Text } from "@chakra-ui/react";

import { FinancialInfo, IIncome, IRevenue, IStock } from "../../../types/Stock";
import { MONK_SCORE_ENUM, monkScoreToText } from "../../../utils/monkScore";
import ScorePieChart from "./ScorePieChart";
import CompanyMetaRow from "./CompanyMetaRow";
import CompanyHeader from "./CompanyHeader";
import CompanyDataList from "./CompanyDataList";
import CompanyProfile from "./CompanyProfile";
import { data } from "./CompanyProfile/mockData";

import styles from "./styles.module.css";
import CompanyFAQs from "./CompanyFAQs";
import NewMonkScoreCard from "../../../components/Stocks/NewMonkScoreCard";
import NewPositiveSection from "../../../components/Stocks/NewPositiveSection";
import NewNeutralSection from "../../../components/Stocks/NewNeutralSection";
import NewNegativeSection from "../../../components/Stocks/NewNegativeSection";
import ActionsSection from "../../../components/Stocks/actionsSection";
import AlternativeSection from "../../../components/Stocks/AlternativeSection";
import FAQCard from "../../../components/Stocks/CompanyCard/FAQCard";
import CompanyLogoSection from "../../MobileSubView/CompanyLogoSection";
import CardAccordian from "../../../components/Stocks/CardAccordian";
import MonkChartCard from "../../../components/Stocks/CompanyCard/MonkScoreChartCard";
import { handleMonkScoreTitleSubtext } from '../../../utils/formatChartNumber';

type TProps = {
  currentIdx: number;
  compData: IStock;
  financialInfo: FinancialInfo;
  alphaData: {
    AnalystTargetPrice: string;
    DividendPerShare: string;
    DividendYield: string;
    MarketCapitalization: string;
    SharesOutstanding: string;
  };
  stockCurrentPrice: number;
  performance: string;
};

function RightStocksSubView({
  currentIdx = 0,
  compData,
  financialInfo,
  alphaData,
  stockCurrentPrice,
  performance,
}: Readonly<TProps>) {
  
  const { ticker, exchangeTicker } = useParams();
  const scoreValue = monkScoreToText(compData?.monkScore);

  const scoreText = MONK_SCORE_ENUM[scoreValue];
  const lastUpdate = new Date(compData?.lastScoreUpdateDate || Date.now())
    ?.toLocaleDateString()
    ?.replaceAll("/", "-")
    ?.split("-")
    .reverse()
    .join("-");

  return (
    <Flex
      w={{ base: "100%", md: "100%" }}
      flexDirection="column"
      alignItems="flex-start"
      gap="20px"
      backgroundColor="#FFF"
      py="30px"
      borderRadius="24px"
      flexGrow="1"
      overflow={"auto"}
    >
      <Box px={{ base: "20px", md: "40px" }} width={"100%"}>
        <CompanyLogoSection compData={compData} />
      </Box>

      <Flex
        as="section"
        flexDirection={"column"}
        gap={"20px"}
        mb={{ base: "40px", md: "0" }}
        px={{ base: "20px", md: "40px" }}
      >
        <CompanyHeader compData={compData} />
        <Flex
          w={"100%"}
          flexDirection={"row"}
          flexWrap={"wrap"}
          gap="30px"
          justifyContent={{ base: "center", md: "stretch" }}
          alignItems="stretch"
        >
          <Flex
            flexDirection="column"
            w={{ base: "100%", md: "fit-content" }}
            maxWidth="360px"
            borderRadius="20px"
            backgroundColor="#F9FAFB"
            py="20px"
            px={{ base: "15px", md: "25px" }}
            rowGap="20px"
            id="stock-ref-card"
          >
            <Flex gap="10px" alignItems={"center"}>
              <ScorePieChart
                score={
                  compData?.monkScore
                    ? Math.round(compData?.monkScore)
                    : 0
                }
              />
              <Text
                minWidth={"165px"}
                textAlign="center"
                fontSize="16px"
                lineHeight="24px"
                px="10px"
              >
                <Text fontWeight="600" display="inline-block" as="span">
                &nbsp;{`${compData?.companyName??''} ${handleMonkScoreTitleSubtext(compData?.monkScore??0)??''} `}&nbsp;
                </Text>
               
                &nbsp;in next 3 years.
              </Text>
            </Flex>

            <CompanyMetaRow
              compData={compData}
              lastUpdate={lastUpdate}
              idElementForScreenshot="stock-ref-card"
            />
          </Flex>
          <div className={styles.note}>
            <p className={styles.noteText}>
              This is what science tells us about&nbsp;
              {compData?.companyName}’s &nbsp;future
            </p>
          </div>
        </Flex>
      </Flex>

      <Box display={{ base: "none", md: "block" }} overflowX="auto" w="100%">
        <CompanyDataList compData={compData} />
      </Box>

      <NewMonkScoreCard
        compData={compData}
        performance={performance}
        lastUpdate={lastUpdate}
      />

      <Box
        display={{ base: "block", md: "none" }}
        mb="60px"
        overflowX="auto"
        w="100%"
      >
        <CompanyDataList compData={compData} />
      </Box>
      <Box w="100%" px={{ base: "20px", md: "40px" }}>
        <Box w="100%" display="flex" justifyContent="center" mb={3}>
          <CardAccordian
            cardName="MonkScore"
            statusRating={
              compData?.monkScore
                ? Math.round(compData?.monkScore)
                : 0
            }
          >
            <MonkChartCard
              stockData={compData}
              fullTicker={`${exchangeTicker}:${ticker}`}
              companyName={compData?.companyName ?? ""}
              score={compData?.monkScore ?? 0}
            />
          </CardAccordian>
        </Box>

        <NewPositiveSection
          stockData={compData}
          fullTicker={`${exchangeTicker}:${ticker}`}
          lastUpdate={lastUpdate}
        />

        <NewNeutralSection
          stockData={compData}
          fullTicker={`${exchangeTicker}:${ticker}`}
          lastUpdate={lastUpdate}
        />

        <NewNegativeSection
          stockData={compData}
          fullTicker={`${exchangeTicker}:${ticker}`}
          lastUpdate={lastUpdate}
        />

        <ActionsSection stock={compData} />

        <AlternativeSection stockData={compData} />

        <Box w="100%">
          <FAQCard
            compData={compData}
            fullTicker={`${exchangeTicker}:${ticker}`}
          />
        </Box>

        {/* <CompanyFAQs
        compData={compData}
        financialInfo={financialInfo}
        alphaData={alphaData}
        stockCurrentPrice={stockCurrentPrice}
      /> */}

        <CompanyProfile compData={compData} data={data} />
      </Box>
    </Flex>
  );
}

export default React.memo(RightStocksSubView);
