import { Box, Flex, Text } from '@chakra-ui/react'
import './customNegativeChart.css'
import { useCallback, useState } from 'react'
import { CustomNegativeChartData, CustomNegativeChartProps } from './negativeChartTypes'
import { dataInRange, defaultLegendValues, legendPercentages, reverseLegendPercentages,threeLegendPercentages,threeReverseLegendPercentages } from './negativeChartUtils'
import { useCustomChart } from '../useCustomChart'


const CustomNegativeChart = ({ initialData, initialLegendValues = [], reverse = false }: CustomNegativeChartProps) => {
    const formattedData = useCustomChart(initialData)
    const [data, setData] = useState<CustomNegativeChartData>(formattedData)
    const [legendValues, setLegendValues] = useState<(string | number)[]>((reverse === true) ? initialLegendValues.length > 0 ? initialLegendValues : [...defaultLegendValues(initialData).reverse()] : initialLegendValues.length > 0 ? initialLegendValues : [...defaultLegendValues(initialData)])

    const [reverseLegendValues, setReverseLegendValues] = useState<(string | number)[]>((reverse === true) ? initialLegendValues.length > 0 ? [...initialLegendValues.reverse()] : defaultLegendValues(initialData) : initialLegendValues.length > 0 ? [...initialLegendValues.reverse()] : [...defaultLegendValues(initialData).reverse()])

    const lastBar = (yearIndex: number, barIndex: number, value: number) : string =>  
    {
        if(data && yearIndex === data.years.length - 1 && barIndex === data.years[yearIndex].quarters.length - 1 && value > 0){
            return reverse ? 'last-bar-negative' : 'last-bar-positive'
        }
        if(data && yearIndex === data.years.length - 1 && barIndex === data.years[yearIndex].quarters.length - 1 && value < 0){
             return reverse ? 'last-bar-positive' : 'last-bar-negative'
        }
        return '';
    }

    const convertLegendToExponencial = useCallback((legendValue: number) => {
        if(legendValue === 0) return 0
        if(legendValue.toString().length > 3 && legendValue.toString().length < 5){
            return Number.parseFloat(legendValue.toFixed(3))
        }
        return Number.parseFloat(legendValue.toFixed(3)).toExponential()
    }, [])

    const calcPercentage = useCallback((tope: number): number => {
        let legend = (reverse === true) ? [...reverseLegendValues] : [...legendValues]
        console.log("legend",legend)

        
        if(legend.length === 0)return 0
        const positiveTope = (tope > 0 ? tope : tope * -1)
        let positiveMinValue = 0
        if(reverse){
            if(Number.parseFloat((legend[legend.length - 1]).toString()) > 0){
                positiveMinValue = Number.parseFloat((legend[legend.length - 1]).toString())
            }else if(Number.parseFloat((legend[legend.length - 1]).toString()) < 0){
                positiveMinValue = Number.parseFloat((legend[legend.length - 1]).toString()) * -1
            }
        }else{
            if(Number.parseFloat((legend[0]).toString()) > 0){
                positiveMinValue = Number.parseFloat((legend[0]).toString())
            }else if(Number.parseFloat((legend[0]).toString()) < 0){
                positiveMinValue = Number.parseFloat((legend[0]).toString()) * -1
            }
        }
        let positiveMaxValue = 0
        if(reverse){
            if(Number.parseFloat((legend[0]).toString()) > 0){
                positiveMaxValue = Number.parseFloat((legend[0]).toString())
            }else if(Number.parseFloat((legend[0]).toString()) < 0){
                positiveMaxValue = Number.parseFloat((legend[0]).toString()) * -1
            }
        }else{
            if(Number.parseFloat((legend[legend.length - 1]).toString()) > 0){
                positiveMaxValue = Number.parseFloat((legend[legend.length - 1]).toString())
            }else if(Number.parseFloat((legend[legend.length - 1]).toString()) < 0){
                positiveMaxValue = Number.parseFloat((legend[legend.length - 1]).toString()) * -1
            }
        }
        
        let totalPercentage = 0
        if(reverse){
            if(tope > 0){
                totalPercentage = legend.filter(i => Number.parseFloat(i.toString()) > 0).length / (legend.length - 1)
            }
            if(tope < 0){
                totalPercentage = legend.filter(i => Number.parseFloat(i.toString()) < 0).length / (legend.length - 1)
            }
        }else{
            if(tope > 0){
                totalPercentage = legend.filter(i => Number.parseFloat(i.toString()) > 0).length / (legend.length - 1)
            }
            if(tope < 0){
                totalPercentage = legend.filter(i => Number.parseFloat(i.toString()) < 0).length / (legend.length - 1)
            }
        }

        if(!reverse){
            if(tope > 0){
                if(positiveMaxValue === 0)return Number.parseFloat(((Number.parseFloat((positiveTope).toFixed(2)) * totalPercentage) * 100).toFixed(1))
                return Number.parseFloat(((Number.parseFloat((positiveTope / positiveMaxValue).toFixed(2)) * totalPercentage) * 100).toFixed(1))
            }
            if(tope < 0){
                if(positiveMinValue === 0)return Number.parseFloat(((Number.parseFloat((positiveTope).toFixed(2)) * totalPercentage) * 100).toFixed(1))
                return Number.parseFloat(((Number.parseFloat((positiveTope / positiveMinValue).toFixed(2)) * totalPercentage) * 100).toFixed(1))
            }
        }else{
            if(tope > 0){
                if(positiveMinValue === 0)return Number.parseFloat(((Number.parseFloat((positiveTope).toFixed(2)) * totalPercentage) * 100).toFixed(1))
                return Number.parseFloat(((Number.parseFloat((positiveTope / positiveMinValue).toFixed(2)) * totalPercentage) * 100).toFixed(1))
            }
            if(tope < 0){
                if(positiveMaxValue === 0)return Number.parseFloat(((Number.parseFloat((positiveTope).toFixed(2)) * totalPercentage) * 100).toFixed(1))
                return Number.parseFloat(((Number.parseFloat((positiveTope / positiveMaxValue).toFixed(2)) * totalPercentage) * 100).toFixed(1))
            }
        }
        return 0
    }, [legendValues, reverse, reverseLegendValues])

    return (
        <div className='my-chart my-negative-chart'>
            <div className='custom-chart-legend-bar custom-negative-chart-legend-bar'>
                {
                  
                    (reverse === true) ? (


                        (dataInRange(initialData) ? [-1,0,1] : reverseLegendValues).map((value, i,arr) => <span key={i} className={`${i === 0 && 'first-legend'}`}  style={{ 
                            position: 'absolute',
                            bottom: `${(arr.length === 3 ? threeReverseLegendPercentages[i] : reverseLegendPercentages[i]) - 10}%`,
                            right: '15%',
                        }}>
                            
                            <span>{ arr.length === 3 ? Math.ceil(Number.parseFloat(value.toString())) : (i === reverseLegendValues.length - 1) ? Math.ceil(Number.parseFloat(value.toString())) : Number.parseFloat(value.toString()).toFixed(2) }</span>
                        </span>)
                    ) : (
                        (dataInRange(initialData) ? [1,0,-1] : legendValues).map((value, i,arr) => <span key={i} className={`${i === 0 && 'first-legend'}`}  style={{ 
                            position: 'absolute',
                            bottom: `${(arr.length === 3 ? threeLegendPercentages[i] : legendPercentages[i]) - 10}%`,
                            right: '15%',
                        }}>
                            <span>{ arr.length === 3 ? Math.ceil(Number.parseFloat(value.toString())) :  (i === legendValues.length - 1) ? Math.ceil(Number.parseFloat(value.toString())) : Number.parseFloat(value.toString()).toFixed(2) }</span>
                        </span>)
                    )
                }
            </div>
            <table className="custom-negative-chart charts-css column multiple hide-data data-center show-labels datasets-spacing-5">
                <tbody>
                    {
                        data && data.years.length > 0 && data.years.map(({ year, quarters }, index) => {
                            return (
                                <tr key={index}>
                                    <th scope="row" className="year-xaxis">{ year }</th>
                                    {
                                        quarters.map(({ quarter, value }, i) => {
                                            const percentage = calcPercentage(value)
                                            
                                            
                                            return (
                                                <td className="custom-negative-chart-bar" key={i}>
                                                    <div className={`custom-negative-chart-bar-container ${(reverse === true) ? value > 0 ? 'negative' : 'positive' : value > 0 ? 'positive' : 'negative'} ${lastBar(index, i, value)}`} style={{
                                                        height: `${percentage}%`,
                                                        position: 'absolute',
                                                        bottom: `${(reverse === true) ? reverseLegendPercentages[reverseLegendValues.findIndex(i => i === 0)] : legendPercentages[legendValues.findIndex(i => i === 0)]}%`
                                                    }}></div>
                                                    <div className="custom-negative-chart-bar-tooltip" style={
                                                        (reverse === true) ? (
                                                            (value > 0) ? {
                                                                bottom: `-${10 + percentage}%`
                                                            } : {
                                                                top: `${40 - percentage}%`
                                                            }
                                                        ) : (
                                                            (value > 0) ? {
                                                                top: `${40 - percentage}%`
                                                            } : {
                                                                bottom: `-${10 + percentage}%`
                                                            }
                                                        )
                                                    }>
                                                        <div className='custom-negative-chart-bar-wrapper'>
                                                            <Box className={`${ reverse ? (value <= 0 ? 'tooltip-indicator-down triangle' : 'tooltip-indicator-up triangle triangle-up') 
                                                                : (value <= 0 ? 'tooltip-indicator-up triangle triangle-up' : 'tooltip-indicator-down triangle') 
                                                            }`}></Box>
                                                            <Flex justifyContent={'center'} gap={2}>
                                                                <Text
                                                                    className='cashflow'
                                                                    textAlign='center'
                                                                    color='#0A64BC'
                                                                    fontSize='10px'
                                                                    fontWeight={400}
                                                                    fontFamily='Poppins'
                                                                >
                                                                    {`${year} ${quarter}: `}
                                                                </Text>
                                                                <Text
                                                                    className='cashflow'
                                                                    textAlign='center'
                                                                    color='#0A64BC'
                                                                    fontSize='10px'
                                                                    fontWeight={700}
                                                                    fontFamily='Poppins'
                                                                >
                                                                    {value.toFixed(3)}
                                                                </Text>
                                                            </Flex>
                                                        </div>
                                                    </div>
                                                    <span className="data">{ Math.round(value) }</span>
                                                </td>
                                            )
                                        })
                                    }
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </div>
    )
}

export default CustomNegativeChart