import {
  useReactTable,
  getCoreRowModel,
  flexRender,
  ColumnDef,
  PaginationState,
  RowSelectionState,
  SortingState,
  getSortedRowModel,
} from "@tanstack/react-table";
import { Filter } from "../../types/Screener";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Flex,
  Box,
  Select,
  Button,
} from "@chakra-ui/react";
import {
  ChevronRightIcon,
  ChevronLeftIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  ArrowDownIcon,
  ArrowUpIcon,
} from "@chakra-ui/icons";

import {
  Pagination,
  usePagination,
  PaginationPage,
  PaginationContainer,
  PaginationPageGroup,
  PaginationSeparator,
} from "@ajna/pagination";

import { useState, PropsWithChildren, startTransition } from "react";
import { NoneCompanyScreener } from "../Box/None";

export function MonkTable<T>({
  columns,
  data = [],
  page = 1,
  pageSize = 25,
  total = 100,
  onPaginate,
  onRowSelect,
  children,
  filters,
  onSort,
  sort,
  setSort,
}: PropsWithChildren<{
  columns: ColumnDef<T>[];
  data: Array<T>;
  page?: number;
  pageSize?: number;
  total?: number;
  onPaginate?: (pagination: PaginationState) => void;
  onRowSelect?: (selection: RowSelectionState) => void;
  filters?: Filter[];
  onSort?: (sort: SortingState) => void;
  sort?: SortingState;
  setSort?: any;
}>) {
  const {
    currentPage,
    setCurrentPage,
    pagesCount,
    pages,
    pageSize: currentPageSize,
    setPageSize,
  } = usePagination({
    pagesCount: Math.ceil(total / pageSize),
    limits: {
      outer: 3,
      inner: 3,
    },
    initialState: { currentPage: page, pageSize: pageSize },
  });
  const [rowSelection, setRowSelection] = useState({});
  const [sorting, setSorting] = useState<SortingState>([]);
  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    enableSorting: true,
    rowCount: total,
    initialState: {
      sorting: [
        {
          id: "CompanyProfile",
          desc: false,
        },
      ],
    },
    state: {
      pagination: {
        pageIndex: currentPage,
        pageSize: currentPageSize,
      },
      rowSelection,
      sorting: sort,
    },
    manualSorting: true,
    enableRowSelection: true,
    onRowSelectionChange: (onRowSelectionChange) => {
      setRowSelection(onRowSelectionChange);
      onRowSelect && onRowSelect(rowSelection);
    },
    onSortingChange: setSort,
  });

  const isEmpty = data.length === 0;

  // Render the UI for your table
  return (
    <>
      <Table w="100" h="auto">
        <Thead
          fontWeight={700}
          fontSize={"10px"}
          lineHeight={"15px"}
          fontFamily={"Poppins"}
          color="#333333"
        >
          {table.getHeaderGroups().map((headerGroup, index) => (
            <Tr key={index}>
              {headerGroup.headers.map((header) => (
                <Th
                  px={{ base: "10px", lg: "4px", xl: "18px" }}
                  py={{ base: "10px" }}
                  textAlign={"center"}
                  textTransform={"capitalize"}
                  key={header.id}
                  fontWeight={700}
                  fontSize={"10px"}
                  lineHeight={"15px"}
                  color={"#333333"}
                >
                  {header.isPlaceholder ? null : (
                    <Box
                      onClick={header.column.getToggleSortingHandler()}
                      display="flex"
                      alignItems="center"
                      w="100%"
                      fontWeight={700}
                      fontSize={"10px"}
                      lineHeight={"15px"}
                      fontFamily={"Poppins"}
                      color="#333333"
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                      {{
                        asc: <ArrowUpIcon fontSize={"16px"} mr={"10px"} />,
                        desc: <ArrowDownIcon fontSize={"16px"} mr={"10px"} />,
                      }[header.column.getIsSorted() as string] ?? null}
                    </Box>
                  )}
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody>
          {isEmpty ? (
            <Tr>
              <Td colSpan={table.getAllColumns().length}>
                <NoneCompanyScreener filters={filters} />
              </Td>
            </Tr>
          ) : (
            <>
              {table.getRowModel().rows.map((row, i) => {
                return (
                  <Tr key={`row${i}`}>
                    {row.getVisibleCells().map((cell, j) => {
                      return (
                        <Td
                          px={{ base: "10px", lg: "4px", xl: "18px" }}
                          py={{ base: "0px" }}
                          key={cell.id}
                          opacity={j > 2 ? "0.5" : "1"}
                          textAlign={"center"}
                        >
                          {
                            j === 1 ? (
                              <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="flex-start"
                                w="100%"
                              >
                                {flexRender(
                                  cell.column.columnDef.cell,
                                  cell.getContext()
                                )}
                              </Box>
                            ) : (
                              <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                w="100%"
                              >
                                {flexRender(
                                  cell.column.columnDef.cell,
                                  cell.getContext()
                                )}
                              </Box>
                            )
                          }
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
            </>
          )}
        </Tbody>
      </Table>

      <Pagination
        pagesCount={pagesCount}
        currentPage={currentPage}
        onPageChange={setCurrentPage}
      >
        <PaginationContainer
          mt={"15px"}
          w="100%"
          alignItems={"center"}
          justifyContent={"center"}
        >
          <PaginationPageGroup
            w="100%"
            isInline
            align="center"
            separator={
              <PaginationSeparator
                bg="#A9AAAB"
                fontSize="sm"
                w={"32px"}
                h={"32px"}
                jumpSize={5}
              />
            }
          >
            <Flex as="li" w="52px" h="52px" alignItems={"center"}>
              <Button
                className="pagination-page"
                w="32px"
                h="32px"
                borderRadius="4px"
                bgColor={"#A9AAAB"}
                opacity={0.5}
                alignItems={"center"}
                textAlign={"center"}
                isDisabled={currentPage === 1}
                _hover={{ bgColor: "#212B36" }}
                onClick={() => {
                  onPaginate &&
                    onPaginate({
                      pageIndex: currentPage - 1,
                      pageSize: pageSize,
                    });
                  table.previousPage();

                  setCurrentPage(currentPage - 1);
                }}
              >
                <ChevronLeftIcon color={"#E9EAEB"} h={6} w={6} />
              </Button>
            </Flex>
            {pages.map((page: number) => (
              <PaginationPage
                w="32px"
                h="32px"
                borderRadius="4px"
                border={
                  currentPage === page
                    ? "solid 1px #0564B8"
                    : "solid 1px #F9FAFB"
                }
                color={currentPage === page ? "#0564B8" : "#212B36"}
                bgColor={"##FFFFFF"}
                key={`pagination_page_${page}`}
                page={page}
                onClick={() => {
                  onPaginate &&
                    onPaginate({
                      pageIndex: page + 1,
                      pageSize: pageSize,
                    });
                  table.setPageIndex(page + 1);
                  setCurrentPage(page + 1);
                }}
                fontFamily={"Poppins"}
                textAlign={"center"}
                fontSize={"10px"}
                lineHeight={"20px"}
              />
            ))}
            <Flex as="li" w="52px" h="52px">
              <Button
                className="pagination-page"
                w="32px"
                h="32px"
                borderRadius="4px"
                bgColor={"#A9AAAB"}
                opacity={0.5}
                alignItems={"center"}
                textAlign={"center"}
                isDisabled={currentPage >= pagesCount}
                _hover={{ bgColor: "#212B36" }}
                onClick={() => {
                  table.nextPage();
                  setCurrentPage(currentPage + 1);
                  onPaginate &&
                    onPaginate({
                      pageIndex: currentPage + 1,
                      pageSize: pageSize,
                    });
                }}
                rightIcon={<ChevronRightIcon color={"#E9EAEB"} h={6} w={6} />}
              ></Button>
            </Flex>
          </PaginationPageGroup>
        </PaginationContainer>
      </Pagination>

      <Flex justifyContent="space-between" m={4} alignItems="center">
        <Flex alignItems="center">
          <Select
            w={32}
            value={table.getState().pagination.pageSize}
            onChange={(e) => {
              const size = Number(e.target.value);
              table.setPageSize(size);
              setPageSize(size);
              onPaginate &&
                onPaginate({
                  pageIndex: table.getState().pagination.pageIndex,
                  pageSize: size,
                });
            }}
          >
            {[10, 30, 50, 100].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </Select>
        </Flex>
      </Flex>

      <Flex w={"100%"}>{children}</Flex>
    </>
  );
}
