import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const userApi = createApi({
  reducerPath: "userData",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_SERVER_URL}/api/v1/auth`,
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem("accessToken");
      if (token) {
        const replacedToken = token.replace(/['"]+/g, "").trim();
        headers.set("authorization", replacedToken);
        headers.set("token", replacedToken);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    fetchSubscriptionData: builder.query({
      query: () => `/user-subscription`,
      transformResponse: (response: any) => {
        localStorage.setItem("user-subscription",JSON.stringify(response))
        return response;
      },
    }),
  }),
});

export const { useFetchSubscriptionDataQuery  } = userApi;
