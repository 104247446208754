import { useCallback, useState } from "react";
import {
    Bar,
    BarChart,
    Cell,
    Tooltip,
    ResponsiveContainer,
    XAxis,
    YAxis,
} from "recharts";
import { Box, Flex, Text, useBreakpoint } from "@chakra-ui/react";

export interface EntryChartProps {
    useValue?: boolean,
    data: {
        percentile: number;
        value: number;
        year: string;
        period: string
    }[];
}

export function EntryChart({ data = [], useValue = false }: EntryChartProps) {

    const breakpoint = useBreakpoint();

    const CustomTooltip = ({ active, payload, label }) => {
        if(!active || !payload || !payload.length){
            return null
        }
        let payloadValue = payload[0]?.value || 0;
        return (
            <Box
                className='custom_tooltip'
                position='relative'
                style={{
                    background: 'white',
                    padding: '6px',
                    borderRadius: '10px',
                    border: '1px solid #D3D5DA',
                    zIndex: 1000,
                    outline: 'none !important',
                }}
            >
                {(
                <Box
                    className={payloadValue > 0 ? 'triangle_down' : 'triangle_up'}
                    position='absolute'
                    left='38%'
                    bottom={payloadValue > 0 ? '-15%' : '100%'}
                />
                )}
                <Flex flexDirection={'row'} gap={1}>
                    <Text
                        className='cashflow'
                        textAlign='center'
                        color='#0A64BC'
                        fontSize='10px'
                        fontWeight={400}
                        fontFamily='Poppins'
                    >
                        {payload[0]?.payload?.date.getFullYear()} {payload[0]?.payload?.period}:
                    </Text>
                    <Text
                        className='cashflow'
                        textAlign='center'
                        color='#0A64BC'
                        fontSize='10px'
                        fontWeight={700}
                        fontFamily='Poppins'
                    >
                        {Math.round(payload[0]?.payload?.percentile ?? payload[0]?.payload?.value ?? 0)}
                    </Text>
                </Flex>
            </Box>
        )
    }

    const formattedData = useCallback(() => {
        return data.map((item) => ({
            date: new Date(parseInt(item.year), 0, 1),
            value: item.value,
            percentile: item.percentile,
            period: item.period
        }));
    }, [data])

    const [hoverIndex, setHoverIndex] = useState<string>();
    const [position, setPosition] = useState({
      data: { x: 0, y: 0 },
      show: false,
    });

    const isMobile = breakpoint === "base";
    const style= { width: "100%", height: isMobile ? "32px" : "100px" }
    return (
        <div style={style}>
            <ResponsiveContainer width="100%" height="100%" aspect={isMobile ? 1 : 0}>
                <BarChart
                    width={500}
                    height={500}
                    data={formattedData()}
                    margin={{
                        top: 0,
                        right: isMobile ? 0 : 30,
                        left: isMobile ? -20 : 20,
                        bottom: 0,
                    }}
                    barGap={5}
                >
                    <XAxis axisLine={false} tickLine={false} tick={false} />
                    <YAxis
                        type="number"
                        domain={[0, 100]}
                        axisLine={false}
                        tickLine={false}
                        fontSize={"12px"}
                        padding={{ bottom: 3 }}
                        tickMargin={isMobile ? 5 : 20}
                        tick={false}
                    />
                    <Tooltip
                        wrapperStyle={{ outline: "none" }}
                        content={<CustomTooltip active payload label />}
                        cursor={{ fill: "transparent" }}
                        position={{
                        x: position?.data.x - 10 ?? 0,
                        y:
                            position?.data.y -
                            (data[Number(hoverIndex)]?.value > 0 ? data[Number(hoverIndex)]?.percentile > 50 ? 0 : 30 : -8) ?? 0,
                        }}
                    />
                    
                    <Bar 
                        barSize={100}
                        radius={[5, 5, 0, 0]}
                        dataKey={useValue ? "value" : "percentile"}
                        onMouseMove={(data) => setPosition({ data: data, show: true })}
                        onMouseLeave={(data) => setPosition({ data: data, show: false })}
                    >
                        {data.map((entry, i, arr) => {
                            const isCurrent = i + 1 === arr.length;
                            return (
                                <Cell
                                    key={`cell-${i}`}
                                    onMouseEnter={() => {
                                        if (i) {
                                        setHoverIndex(`${i}`);
                                        } else {
                                        setHoverIndex("");
                                        }
                                    }}
                                    fill={Number(hoverIndex) === i ? "#085096" : "#CEE0F1"}
                                    fillOpacity={
                                        Number(hoverIndex) === i ? 1 : isCurrent ? 1 : ".25"
                                    }
                                />)
                        })}
                    </Bar>
                        
                </BarChart>
            </ResponsiveContainer>
        </div>
    )
}