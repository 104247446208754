import { Box, Flex, Text, Button } from "@chakra-ui/react";
import { FormattedMessage } from "react-intl";
import GrowthBadge from "../../common/badges/GrowthBadge";
import GenericChart from "../GenericChartScores/GenericChart";
import {
  handleGrowthBadgeValueDifference,
  handleTitleSubtext,
} from "../../../utils/formatChartNumber";
import { EntryBoxChart } from "../EntryChart/Box";
import CompanyMetaRow from "../../../subviews/RightSubview/Stocks/CompanyMetaRow";
import { IStock } from "../../../types/Stock";
import "./MonkScoreChartCard.css";
import { useDataChart } from "../../../hooks/useDataChart";
import { useMemo } from "react";

interface MonkScoreCardProps {
  stockData: IStock;
  fullTicker: string;
  companyName: string;
  score: number;
}

const MonkChartCard = ({
  companyName,
  score,
  stockData,
}: MonkScoreCardProps) => {
  
  const { data, getAllChartsSanitizedData } = useDataChart();

  const { monkScoreTimeline,
    valuationScoreTimeline,
    growtScoreTimeline,
    profitabilityScoreTimeline,
    healthScoreTimeline,
    shareholderScoreTimeline } = getAllChartsSanitizedData();

  const sign = "pts";

  const oneYearMetric = () => {
    if (data) {
      const monkscore = monkScoreTimeline;
      return Math.round(
        monkscore.length > 0 ? monkscore[monkscore.length - 1].percentile : 0
      );
    }
    return 0;
  };

  const oneYearMetricDifference = useMemo(() => {
    if (data) {
      const monkscore = monkScoreTimeline;
      return handleGrowthBadgeValueDifference(monkscore,'percentile');
    }
    return 0;
  }, [data, monkScoreTimeline]);

  const lastUpdate = new Date(stockData?.lastScoreUpdateDate || Date.now())
    ?.toLocaleDateString()
    ?.replaceAll("/", "-")
    ?.split("-")
    .reverse()
    .join("-");

  const onClickChart = (anchor: string) => window.location.hash = anchor;

  return (
    <Box w="100%" h="auto" m="auto" display={"flex"} flexDirection={"column"}>
      <Text as={"p"} fontSize={"14px"} lineHeight={"21px"} fontWeight={700}>
        <FormattedMessage
          id="factors_fundaments"
          values={{
            name: companyName,
            quality: handleTitleSubtext(score)?.toLowerCase(),
          }}
        />{" "}
        <Text as="span" fontSize={"14px"} lineHeight={"21px"} fontWeight={400}>
          based on the fundamental factors that science has identified as the
          most reliable predictors of future returns.
        </Text>
      </Text>

      <Box bgColor="#F9FAFB" id="MonkScoreCard">
        <Box
          position="relative"
          my={"10px"}
          display='flex'
          flexDirection='column'
          gap='20px'
          backgroundColor={"#FFF"}
          borderRadius={"20px"}
          pt="20px"
          pb="2px"
        >
          <Flex flexDirection='column' gap="20px" px="20px">
            <Flex flexDirection={"column"}>
              <Flex
                justifyContent={"space-between"}
                alignItems={"flex-start"}
                gap="10px"
              >
                <Text
                  as={"p"}
                  fontSize={"24px"}
                  lineHeight={"36px"}
                  fontWeight={500}
                  display={"flex"}
                  alignItems={"baseline"}
                >
                  {Math.round(oneYearMetric())}<Text as={"p"} fontSize={"12px"}>/100</Text>
                </Text>
                <Button
                  width={"auto"}
                  fontSize={"14px"}
                  lineHeight={"21px"}
                  fontWeight={400}
                  display={"flex"}
                  alignItems={"center"}
                  variant={"unstyled"}
                  p="0"
                  height={"auto"}
                  justifyContent={"flex-end"}
                  flexWrap={"wrap"}
                  wordBreak={"break-all"}
                  textDecoration="none"
                  columnGap={"4px"}
                >
                  <Text display={"flex"} alignItems={"center"}>
                    <FormattedMessage
                      id="evolution"
                      values={{
                        name: "MonkScore®",
                      }}
                    />{" "}
                  </Text>
                </Button>
              </Flex>

              <Box>
                <GrowthBadge
                  vector={oneYearMetricDifference > 0 ? "up" : "down"}
                  meta={`${
                    oneYearMetricDifference > 0 ? "+" : ""
                  }${oneYearMetricDifference.toFixed(2)}${sign} last 1 year`}
                />
              </Box>
            </Flex>

            <Box>
              <GenericChart data={monkScoreTimeline} />
            </Box>
          </Flex>

          <Box px="10px">
            <CompanyMetaRow
              compData={stockData}
              lastUpdate={lastUpdate}
              idElementForScreenshot={`MonkScoreCard`}
              isChart
              styledBox
            />
          </Box>
        </Box>
      </Box>
      <Box w="100%" my="10px">
        <Text
          id="message-container"
          as={"p"}
          fontSize={"14px"}
          lineHeight={"21px"}
        >
          <FormattedMessage id="factor_below_prefix" />
          <FormattedMessage
            id="factors_below"
            values={{
              name: companyName,
            }}
          >
            {(txt) => <span className="highlight">{txt}</span>}
          </FormattedMessage>
        </Text>
      </Box>

      <Box bgColor="#F9FAFB" id="MonkScoreCardRatios">
        <Box
          my={"10px"}
          backgroundColor={"#FFF"}
          borderRadius={"20px"}
          pt="20px"
          pb="2px"
          display={"flex"}
          flexDirection={"column"}
        >
          <Box px="20px">
            <EntryBoxChart name="Valuation" anchor="ValuationCard" onGoTo={onClickChart} data={valuationScoreTimeline} />
            <EntryBoxChart name="Growth" anchor="GrowthCard" onGoTo={onClickChart} data={growtScoreTimeline} />
            <EntryBoxChart
              name="Profitability"
              anchor="ProfitabilityCard"
              onGoTo={onClickChart}
              data={profitabilityScoreTimeline}
            />
            <EntryBoxChart name="Health" anchor="HealthCard" onGoTo={onClickChart} data={healthScoreTimeline} />
            <EntryBoxChart
              name="Shareholder Retribution"
              anchor="ShareholderCard"
              onGoTo={onClickChart}
              data={shareholderScoreTimeline}
            />
          </Box>

          <Box px="10px">
            <CompanyMetaRow
              compData={stockData}
              lastUpdate={lastUpdate}
              idElementForScreenshot={`MonkScoreCardRatios`}
              isChart
              styledBox
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default MonkChartCard;
