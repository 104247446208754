import { Box, Flex, Text, Button } from "@chakra-ui/react";
import { useCallback, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import GrowthBadge from "../../common/badges/GrowthBadge";
import GenericChart from "../GenericChartScores/GenericChart";
import {
  FormatChartNumber,
  handleGrowthBadgeValueDifference,
  handleTitleSubtext,
  handleValuationTitleSubtext,
} from "../../../utils/formatChartNumber";
import { EntryBoxChart } from "../EntryChart/Box";
import CompanyMetaRow from "../../../subviews/RightSubview/Stocks/CompanyMetaRow";
import { useDispatch, useSelector } from "react-redux";
import {
  actualScore,
  actualStock,
  openActiveChart,
} from "../../../store/slices/stock";
import { useScaleCompanyRatio } from "../../../hooks/useData";
import "./MonkScoreChartCard.css";
import { SupportedChartOption, useDataChart } from "../../../hooks/useDataChart";

const ValuationChartCard = () => {

  const { data, getChartSanitizedData: getChartValuation } = useDataChart(SupportedChartOption.valuationScoreTimeline);

  const stock = useSelector(actualStock);

  const dispatch = useDispatch();

  const onClickChart = (name: string) => {
    setTimeout(() => {
      dispatch(
        openActiveChart({
          type: "valuation",
          active: name,
        }) as any
      );
    }, 100);
  };

  const sign = "pts";

  const oneYearMetric = () => {
    if (data) {
      const valuation = getChartValuation();
      return Math.round(
        valuation.length > 0 ? valuation[valuation.length - 1].percentile : 0
      );
    }
    return 0;
  };

  const oneYearMetricDifference = useMemo(() => {
    if (data) {
      const valuation = getChartValuation();
      return handleGrowthBadgeValueDifference(valuation,'percentile');
    }
    return 0;
  }, [data, getChartValuation]);

  const lastUpdate = new Date(stock?.lastScoreUpdateDate || Date.now())
    ?.toLocaleDateString()
    ?.replaceAll("/", "-")
    ?.split("-")
    .reverse()
    .join("-");

  const { chartData } = useScaleCompanyRatio();

  const intl = useIntl();

  return (
    <Box w="100%" h="auto" m="auto" display={"flex"} flexDirection={"column"}>
      <Text as={"p"} fontSize={"14px"} lineHeight={"21px"} fontWeight={700}>
        <FormattedMessage
          id="factors_unique_generic"
          values={{
            name: stock?.companyName ?? "",
            quality: handleValuationTitleSubtext(oneYearMetric())?.toLowerCase(),
          }}
        />{" "}
        <Text as="span" fontSize={"14px"} lineHeight={"21px"} fontWeight={400}>
          compared to its peers.
        </Text>
      </Text>

      <Box bgColor="#F9FAFB" id="ValuationScoreCard">
        <Box
          position="relative"
          my={"10px"}
          backgroundColor={"#FFF"}
          borderRadius={"20px"}
          pt="20px"
          pb="2px"
          display='flex'
          flexDirection='column'
          gap='20px'
        >
          <Flex flexDirection={'column'} gap="20px" px="20px">
            <Flex flexDirection={'column'}>
              <Flex
                justifyContent={"space-between"}
                alignItems={"flex-start"}
                gap="10px"
              >
                <Text
                  as={"p"}
                  fontSize={"24px"}
                  lineHeight={"36px"}
                  fontWeight={500}
                  display={"flex"}
                  alignItems={"baseline"}
                >
                  {Math.round(oneYearMetric())}<Text as={"p"} fontSize={"12px"}>/100</Text>
                </Text>

                <Button
                  width={"auto"}
                  fontSize={"14px"}
                  lineHeight={"21px"}
                  fontWeight={400}
                  display={"flex"}
                  alignItems={"center"}
                  variant={"unstyled"}
                  p="0"
                  height={"auto"}
                  justifyContent={"flex-end"}
                  flexWrap={"wrap"}
                  wordBreak={"break-all"}
                  textDecoration="none"
                  columnGap={"4px"}
                >
                  <Text display={"flex"} alignItems={"center"}>
                    <FormattedMessage
                      id="evolution"
                      values={{
                        name: "Health",
                      }}
                    />{" "}
                  </Text>
                </Button>
              </Flex>

              <Box>
                <GrowthBadge
                  vector={oneYearMetricDifference > 0 ? "up" : "down"}
                  meta={`${
                    oneYearMetricDifference > 0 ? "+" : ""
                  }${oneYearMetricDifference.toFixed(2)}${sign} last 1 year`}
                />
              </Box>
            </Flex>

            <Box>
              <GenericChart data={getChartValuation()} />
            </Box>
          </Flex>

          <Box px="10px">
            {stock && (
              <CompanyMetaRow
                compData={stock}
                lastUpdate={lastUpdate}
                idElementForScreenshot={`ValuationScoreCard`}
                isChart
                styledBox
              />
            )}
          </Box>
        </Box>
      </Box>
      <Box w="100%" my="10px">
        <Text
          id="message-container"
          as={"p"}
          fontSize={"14px"}
          lineHeight={"21px"}
        >
          <FormattedMessage id="factor_below_prefix" />
          <FormattedMessage
            id="factor_metric_company"
            values={{
              name: stock?.companyName ?? "",
            }}
          >
            {(txt) => <span className="highlight">{txt}</span>}
          </FormattedMessage>
          <FormattedMessage
            id="factor_subfix"
            values={{
              metric_name: intl.formatMessage({ id: "valuation_name" }),
            }}
          />
        </Text>
      </Box>

      <Box bgColor="#F9FAFB" id="ValuationCardRatios">
        <Box
          my={"10px"}
          backgroundColor={"#FFF"}
          borderRadius={"20px"}
          pt="20px"
          pb="2px"
          display={"flex"}
          flexDirection={"column"}
        >
          <Box px="20px">
            <Text
              my={"20px"}
              fontWeight={600}
              fontSize={"16px"}
              lineHeight={"24px"}
            >
              Price Percentiles
            </Text>
            <EntryBoxChart
              onClick={onClickChart}
              useValue={false}
              name="Price / Sale"
              data={chartData("Price / Sale")}
            />
            <EntryBoxChart
              onClick={onClickChart}
              useValue={false}
              name="Price / Earnings"
              data={chartData("Price / Earnings")}
            />
            <EntryBoxChart
              onClick={onClickChart}
              useValue={false}
              name="Price / FCF"
              data={chartData("P/FCF")}
            />
            <EntryBoxChart
              onClick={onClickChart}
              useValue={false}
              name="Price / Earnings Growth"
              data={chartData("Price / Earnings Growth")}
            />
            <Text
              my={"20px"}
              fontWeight={600}
              fontSize={"16px"}
              lineHeight={"24px"}
            >
              Enterprise Value Percentiles
            </Text>
            <EntryBoxChart
              onClick={onClickChart}
              useValue={false}
              name="EV / Revenue"
              data={chartData("EV / Revenue")}
            />
            <EntryBoxChart
              onClick={onClickChart}
              useValue={false}
              name="EV / Operating Earnings"
              data={chartData("EV / Operating Earnings")}
            />
            <EntryBoxChart
              onClick={onClickChart}
              useValue={false}
              name="EV / EBITDA"
              data={chartData("EV / EBITDA")}
            />
            <EntryBoxChart
              onClick={onClickChart}
              useValue={false}
              name="EV / FCF"
              data={chartData("EV / FCF")}
            />
          </Box>

          <Box px="10px">
            {stock && (
              <CompanyMetaRow
                compData={stock}
                lastUpdate={lastUpdate}
                idElementForScreenshot={`ValuationCardRatios`}
                isChart
                styledBox
              />
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ValuationChartCard;
