import {
  Bar,
  BarChart,
  Cell,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { FC, useCallback, useEffect, useRef, useState } from "react";

import CustomChart from "../../common/Charts/CustomChart/CustomChart";
import { CustomChartDataProps } from "../../common/Charts/CustomChart/chartTypes";
import { CustomTooltip } from "./CustomTooltip";
import { useBreakpoint } from "@chakra-ui/react";
import { useIntl } from "react-intl";

export interface GenericChartProps {
  lessCurved?: boolean;
  altColor?: boolean;
  data: {
    percentile: number;
    value: number;
    year: string;
    period: string;
    statementsDate?: string;
    processedAsOfDate?: string;
  }[];
}



const GenericChart: FC<GenericChartProps> = ({
  data,
  lessCurved,
  altColor,
}) => {
  
  const breakpoint = useBreakpoint();
  const isMobile = breakpoint === "base";

 
  const [hoverIndex, setHoverIndex] = useState<string>();
  const [position, setPosition] = useState({
    data: { x: 0, y: 0 },
    show: false,
  });

  const [formattedData, setFormattedData] = useState<CustomChartDataProps[]>([]);

  useEffect(() => {
    const tempData : CustomChartDataProps[]  = data.map(item => {
      return {
        fullDate: `${item.year}-${item.period}`,
        date: item.year,
        period: item.period,
        percentile: item.percentile
      }
    })

    setFormattedData(tempData);
  
  }, [data]);

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <CustomChart initialData={formattedData} />
    </div>
  );
};

export default GenericChart;
