import { Input, Box, Text } from "@chakra-ui/react"
import { useRef, useState } from "react";

interface TextNumberInputProps {
    value: number
    onChange: (value:number)=>void
}
export function TextNumberInput({value, onChange}: TextNumberInputProps) {
    const inputRef = useRef<any>()

    const handleChange = (event) => {
        if(inputRef.current) {
            inputRef.current.style.width = `${(event.target.value.length < 5 ? inputRef.current.style.width : 30 + ((event.target.value.length + 1) * 8))}px`
        }
        onChange(parseFloat(event.target.value))
    }
    return (
        <Box minW={'80px'} w='auto' maxW={'20vw'} bgColor={"#fff"} color="#085096">
            <Input ref={inputRef} w={'80px'} maxW={'20vw'} value={value} onChange={handleChange} type='number' placeholder='Value' />
        </Box>
    )
}

interface TextBtwNumberInputProps{
    start?: number
    end?: number
    onChange: (start:number,end:number)=>void
}

export function TextBtwNumberInput({ start = 0, end = 100, onChange }: TextBtwNumberInputProps) {
    const inputRefStart = useRef<any>()
    const inputRefEnd = useRef<any>()
    const [startValue, setStartValue] = useState(start ?? 0)
    const [endValue, setEndValue] = useState(end ?? 100)

    const handleChangeStart = (event) => {
        const val = parseFloat(event.target.value)
        setStartValue(val)
        onChange(val,endValue)
       
    }

    const handleChangeEnd = (event) => {
        const val = parseFloat(event.target.value)
        setEndValue(val)
        onChange(val, startValue)
        
    }

    const handleChangeInputStart = (event, callback) => {
        if(inputRefStart.current) {
            inputRefStart.current.style.width = `${(event.target.value.length < 5 ? inputRefStart.current.style.width : 30 + ((event.target.value.length + 1) * 8))}px`
        }
        callback()
    }
    const handleChangeInputEnd = (event, callback) => {
        if(inputRefEnd.current) {
            inputRefEnd.current.style.width = `${(event.target.value.length < 5 ? inputRefEnd.current.style.width : 30 + ((event.target.value.length + 1) * 8))}px`
        }
        callback()
    }

    return (
        <Box display="flex" alignItems="center" gap="10px">
            <Box minW={'60px'} w='auto' maxW={'10vw'}  color="#085096">
                <Input ref={inputRefStart} w={'80px'} maxW={'20vw'} bgColor={"#fff"} fontWeight={500} fontSize={"12px"} lineHeight={"20px"} color="#085096" value={startValue} onChange={(event) => handleChangeInputStart(event, handleChangeStart)} type='number' placeholder='Value' />
            </Box>
            <Text fontFamily={"Poppins"} fontWeight={400} fontSize={"14px"} lineHeight={"20px"}>and</Text>
            <Box minW={'60px'} w='auto' maxW={'10vw'}>
                <Input ref={inputRefEnd} w={'80px'} maxW={'20vw'} bgColor={"#fff"} fontWeight={500} fontSize={"12px"} lineHeight={"20px"} color="#085096" value={endValue} onChange={(event) => handleChangeInputEnd(event, handleChangeEnd)} type='number' placeholder='Value' />
            </Box>
        </Box>
    )
}