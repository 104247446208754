import { useEffect, useState } from 'react';
import './customChart.css'
import { CustomChartData, CustomChartProps } from './chartTypes';
import { defaultLegendValues } from './chartUtils';
import { Box, Flex, Text } from '@chakra-ui/react';
import { useCustomChart } from '../useCustomChart';

const CustomChart = ({ initialData, initialLegendValues = defaultLegendValues }: CustomChartProps) => {
    const formattedData = useCustomChart(initialData)
    const [legendValues] = useState(initialLegendValues)
    const [data, setData] = useState<CustomChartData>(formattedData)

    useEffect(() => {
      setData(formattedData);
    }, [initialData]);
    

    const lastBar = (yearIndex: number, barIndex: number) : string =>  
    {
        if(yearIndex === data.years.length - 1 && barIndex === data.years[yearIndex].quarters.length - 1) return 'last-bar';
        return '';
    }

    return (
        <div className='my-chart'>
            <div className='custom-chart-legend-bar'>
                {
                    legendValues.map((value, i) => <span key={i} className={`${i === 0 && 'first-legend'}`}>{ value }</span>)
                }
            </div>
            <table className="custom-chart charts-css column multiple hide-data data-center show-labels datasets-spacing-5">
                <tbody>
                    {
                        data.years.length > 0 && data.years.map(({ year, quarters }, index) => {
                            return (
                                <tr key={index}>
                                    <th scope="row" className="year-xaxis">{ year }</th>
                                    {
                                        quarters.map(({ quarter, value }, i) => {
                                            return (
                                                <td className={`custom-chart-bar ${lastBar(index, i)}`} key={i} style={{height: `${value}%`}}>
                                                    <div className='custom-chart-bar-tooltip'>
                                                        <div className='custom-chart-bar-wrapper'>
                                                            <Box className='tooltip-indicator triangle_down'></Box>
                                                            <Flex justifyContent={'center'} gap={2}>
                                                                <Text
                                                                    className='cashflow'
                                                                    textAlign='center'
                                                                    color='#0A64BC'
                                                                    fontSize='10px'
                                                                    fontWeight={400}
                                                                    fontFamily='Poppins'
                                                                >
                                                                    {`${year} ${quarter}: `}
                                                                </Text>
                                                                <Text
                                                                    className='cashflow'
                                                                    textAlign='center'
                                                                    color='#0A64BC'
                                                                    fontSize='10px'
                                                                    fontWeight={700}
                                                                    fontFamily='Poppins'
                                                                >
                                                                    {Math.round(value)}
                                                                </Text>
                                                            </Flex>
                                                        </div>
                                                    </div>
                                                    <span className="data">{ Math.round(value) }</span>
                                                </td>
                                            )
                                        })
                                    }
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </div>
    );
};

export default CustomChart;
