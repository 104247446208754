import { Provider } from "react-redux";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./App.css";
import Layout from "./components/Layout";
import { TooltipModalProvider } from "./components/Stocks/TooltipModal";
import Company from "./pages/company";
import Disclaimer from "./pages/Disclaimer";
import Home from "./pages/Home";
import Legal from "./pages/Legal";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import { store } from "./store/store";
import IframeModal from "./components/Modals/IframeModal";
import LocaleModal from "./components/Modals/LocaleModal";
import { UserModal } from "./components/Modals/UserModal";
import { WatchList } from "./pages/Watchlist";
import { PrivateRoute } from "./components/Auth/PrivateRouter";
import { AnimatePresence } from "framer-motion";
import ResetPasswordModal from "./components/Modals/ResetPassword";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Screener } from "./pages/Screener";
import { PageTest } from "./pages/test";
import { PricingModal } from "./components/Modals/PricingModal";
import ProfilePage from "./pages/profile";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <Layout showLogo={false} active="home" key={"home"}>
        <Home />
      </Layout>
    ),
  },
  {
    path: "/stocks/:exchangeTicker/:ticker",
    element: (
      <Layout active="stocks" key={"stocks"}>
        <Company />
      </Layout>
    ),
  },
  {
    path: "/privacy-policy",
    element: (
      <Layout active="stocks">
        <PrivacyPolicy />
      </Layout>
    ),
  },
  {
    path: "/legal",
    element: (
      <Layout active="stocks">
        <Legal />
      </Layout>
    ),
  },
  {
    path: "/disclaimer",
    element: (
      <Layout active="stocks">
        <Disclaimer />
      </Layout>
    ),
  },
  {
    path: "/watchlist/:id?",
    element: (
      <PrivateRoute>
        <Layout active="watchlist" key={"watchlist"}>
          <AnimatePresence>
            <DndProvider backend={HTML5Backend}>
              <WatchList />
            </DndProvider>
          </AnimatePresence>
        </Layout>
      </PrivateRoute>
    ),
  },
  {
    path: "/portfolio/:id?",
    element: (
      <PrivateRoute>
        <Layout active="portfolio" key={"portfolio"}>
          <AnimatePresence>
            <DndProvider backend={HTML5Backend}>
              <WatchList is="portfolio" />
            </DndProvider>
          </AnimatePresence>
        </Layout>
      </PrivateRoute>
    ),
  },
  {
    path: "/screener/:id?",
    element: (
      <PrivateRoute>
        <Layout active="screener" key={"screener"}>
          <AnimatePresence>
            <Screener />
          </AnimatePresence>
        </Layout>
      </PrivateRoute>
    ),
  },
  {
    path: "/profile",
    element: (
      <PrivateRoute>
        <Layout active="profile" key={"profile"}>
          <ProfilePage />
        </Layout>
      </PrivateRoute>
    ),
  },
]);

function App() {
  return (
    <Provider store={store}>
      <TooltipModalProvider>
        <RouterProvider router={router} />
      </TooltipModalProvider>
      <IframeModal />
      <LocaleModal />
      <UserModal />
      <PricingModal />
      <ResetPasswordModal />
    </Provider>
  );
}

export default App;
