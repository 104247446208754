import { getSizes } from "../../../utils/billingPlanHelpers";
import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Spinner,
  Switch,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import { billingPlans } from "./data";
import { useState } from "react";
import { useLocalStorage } from "../../../hooks/useLocalStorage";
import axios from "axios";
import { useFetchSubscriptionDataQuery } from "../../../services/userApi";

export default function PricingCards({
  isMonthly,
  setIsMonthly,
  showFree = false,
  showCurrentSubscription = false,
}) {
  const [loading, setLoading] = useState(-1);
  const [accessToken, setAccessToken] = useLocalStorage("accessToken", "");
  const { isLoading, data: subscriptionData } =
    useFetchSubscriptionDataQuery(accessToken);

  const subPriority = ["free", "basic", "advanced", "pro"];


  const getCheckoutUrl = async (userId:string, plan: string) => {
    let planIndex = {
      free: 3,
      basic: 2,
      advanced: 1,
      pro: 0,
    };
    const getProductName = () => {
      if (plan === "free") {
        return null;
      }
      if (plan === "basic") {
        return isMonthly ? "BASIC_MONTHLY" : "BASIC_YEARLY";
      }

      if (plan === "advanced") {
        return isMonthly ? "ADVANCED_MONTHLY" : "ADVANCED_YEARLY";
      }

      if (plan === "pro") {
        return isMonthly ? "PRO_MONTHLY" : "PRO_YEARLY";
      }
    };
    setLoading(planIndex[plan]);
    try {
      const authorization = accessToken.replace(/['"]+/g, "").trim();

      // make api call to get checkout url
      const resp = await axios.post(
        `${
          process.env.REACT_APP_SERVER_URL
        }/api/v1/payments/${getProductName()}`,
        {},
        {
          headers: {
            token: authorization,
            authorization: authorization,
          },
        }
      );

      if (resp.status === 200) {
        console.log(`checkout url:${resp.data.data}`);
        // redirect to checkout url in a new tab
        // window.open(resp.data.data, "_blank");
        window.location.href = resp.data.data;
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(-1);
    }
  };

  return (
    <Box
      display="flex"
      rowGap={"14px"}
      flexDirection={"column"}
      alignItems={"center"}
    >
      <Box
        display={"flex"}
        flexDirection={"row"}
        alignItems={"center"}
        gap={"10px"}
        fontSize={"sm"}
      >
        <Text color={isMonthly ? "#0A64BC" : ""}>Billed Monthly</Text>
        <Switch
          size="lg"
          isChecked={!isMonthly}
          color={"#0A64BC"}
          onChange={() => setIsMonthly(!isMonthly)}
        />
        <Text color={!isMonthly ? "#0A64BC" : ""}>Billed yearly</Text>
      </Box>
      <Box
        display={{ base: "none", md: "flex" }}
        flexDirection={"row"}
        alignItems={"center"}
        gap={"30px"}
      >
        {Object.values(billingPlans).map((plan, idx) => {
          //  if (plan.name === "free" && !showFree) return null;
          if (plan.name === "free" && subscriptionData?.subscriptionPlan==='free') return null;
          if (subscriptionData?.subscriptionPlan.toLowerCase().includes(plan.name)) return null;
          let { imageSize, width } = getSizes(plan);
          return (
            <Box
              key={plan.name}
              display={"flex"}
              flexDirection={"column"}
              gap={"15px"}
              // minW={"200px"}
              maxW={"250px"}
              backgroundColor={plan.name === "advanced" ? "" : "#F9FAFB"}
              style={{
                background:
                  plan.name === "advanced"
                    ? "linear-gradient(to bottom,  #04284B, #085096)"
                    : "",
              }}
              color={plan.name === "advanced" ? "#FFFFFF" : ""}
              p={"30px"}
              borderRadius={"20px"}
              boxSizing="content-box"
            >
              <img
                alt={`${plan?.title} plan image`}
                src={`/images/${plan.name}_plan_bg.png`}
                width={imageSize}
                height={imageSize}
                style={{
                  margin: "0 auto",
                  marginBottom: "12px",
                }}
              />
              <Box
                display="flex"
                flexDirection={"column"}
                gap="5px"
                alignItems={"start"}
              >
                <Box
                  display={"flex"}
                  flexDirection={"row"}
                  alignItems={"baseline"}
                >
                  {!isMonthly && plan.name !== "free" && (
                    <Text
                      fontSize={"2xl"}
                      fontWeight={"bold"}
                      textDecoration={"line-through"}
                      marginRight={"5px"}
                      color={plan.name === "advanced" ? "#0A64BC" : "#D3D5DA"}
                    >
                      ${plan.price.monthlyStandard}
                    </Text>
                  )}
                  <Text fontSize={"2xl"} fontWeight={"extrabold"}>
                    $
                    {isMonthly
                      ? plan.price.monthlyDiscounted
                      : plan.price.yearlyDiscounted}
                  </Text>
                  <Text fontSize={"sm"} fontStyle={"italic"} ml={"5px"}>
                    /month
                  </Text>
                </Box>
                {!isMonthly && plan.name !== "free" && (
                  <Text
                    fontStyle={"italic"}
                    fontSize={"smaller"}
                    color={plan.name === "advanced" ? "" : "#4D5461"}
                  >
                    billed as ${plan.price.yearlyTotal} per year
                  </Text>
                )}
              </Box>

              <Text fontSize={"xl"} fontWeight={"bold"}>
                {plan.title}
              </Text>

              <Text fontSize={"smaller"}>{plan.summary}</Text>

              <Box display={"flex"} flexDirection={"column"} gap={"8px"}>
                {plan.optionsList.map((option) => {
                  const [firstWord, secondWord, ...rest] = option.split(" ");

                  return (
                    <Box
                      display="flex"
                      gap="5px"
                      alignItems={"start"}
                      className="option-item"
                      fontSize={"smaller"}
                    >
                      <Text fontWeight={"light"}>
                        <Text fontWeight={"semibold"}>
                          {firstWord} {secondWord}
                        </Text>{" "}
                        {rest.join(" ")}
                      </Text>
                    </Box>
                  );
                })}
              </Box>

              <Button
                colorScheme={
                  plan.name ===
                  (subscriptionData?.formattedSubscriptionPlan || "free")
                    ? "whiteAlpha"
                    : "blue"
                }
                w={"100%"}
                h={"56px"}
                minH={"56px"}
                backgroundColor={
                  plan.name !==
                  (subscriptionData?.formattedSubscriptionPlan || "free")
                    ? "#0A64BC"
                    : "white"
                }
                color={
                  plan.name !==
                  (subscriptionData?.formattedSubscriptionPlan || "free")
                    ? "#F9FAFB"
                    : "#0564B8"
                }
                borderRadius={"8px"}
                fontSize={"16px"}
                lineHeight={"24px"}
                fontWeight={600}
                fontFamily={"Poppins"}
                onClick={() => getCheckoutUrl("1",plan.name)}
              >
                {loading === idx ? (
                  <Spinner mr="5px" />
                ) : (
                  <>
                    {plan.name ===
                    (subscriptionData?.formattedSubscriptionPlan || "free")
                      ? "Current Plan"
                      : subPriority.indexOf(plan.name) <
                        subPriority.indexOf(
                          subscriptionData?.formattedSubscriptionPlan || "free"
                        )
                      ? "Downgrade Plan"
                      : "Upgrade to Plan"}
                  </>
                )}
              </Button>
              {showCurrentSubscription &&
                subscriptionData?.subscriptionEndDate &&
                plan.name !== "free" && (
                  <Box>
                    Subscription ends on{" "}
                    {new Date(
                      subscriptionData?.subscriptionEndDate || Date.now()
                    )
                      ?.toLocaleDateString()
                      ?.replaceAll("/", "-")
                      ?.split("-")
                      .reverse()
                      .join("-")}
                  </Box>
                )}
            </Box>
          );
        })}
      </Box>
      <Box display={{ base: "flex", md: "none" }}>
        {/* display tabs */}
        <Tabs>
          <TabList>
            {Object.values(billingPlans).map((plan) => (
              <Tab key={plan.title} value={plan.name}>
                {plan.title}
              </Tab>
            ))}
          </TabList>
          <TabPanels>
            {Object.values(billingPlans).map((plan, idx) => {
              let { imageSize, width } = getSizes(plan);
              //if (plan.name === "free" && !showFree) return null;
              if (plan.name === "free" && subscriptionData.subscriptionPlan==='free') return null;
              if (plan.name === subscriptionData.subscriptionPlan) return null;
    
              return (
                <TabPanel key={plan.title} tabIndex={idx}>
                  <Box
                    display={"flex"}
                    flexDirection={"column"}
                    gap={"15px"}
                    minW={"200px"}
                    maxW={"250px"}
                    backgroundColor={plan.name === "advanced" ? "" : "#F9FAFB"}
                    style={{
                      background:
                        plan.name === "advanced"
                          ? "linear-gradient(to bottom,  #04284B, #085096)"
                          : "",
                    }}
                    color={plan.name === "advanced" ? "#FFFFFF" : ""}
                    p={"30px"}
                    borderRadius={"20px"}
                    boxSizing="content-box"
                  >
                    <img
                      alt={`${plan?.title} plan image`}
                      src={`/images/${plan.name}_plan_bg.png`}
                      width={imageSize}
                      height={imageSize}
                      style={{
                        margin: "0 auto",
                        marginBottom: "12px",
                      }}
                    />
                    <Box
                      display="flex"
                      flexDirection={"column"}
                      gap="5px"
                      alignItems={"start"}
                    >
                      <Box
                        display={"flex"}
                        flexDirection={"row"}
                        alignItems={"baseline"}
                      >
                        {!isMonthly && plan.name !== "free" && (
                          <Text
                            fontSize={"2xl"}
                            fontWeight={"bold"}
                            textDecoration={"line-through"}
                            marginRight={"5px"}
                            color={
                              plan.name === "advanced" ? "#0A64BC" : "#D3D5DA"
                            }
                          >
                            ${plan.price.monthlyStandard}
                          </Text>
                        )}
                        <Text fontSize={"2xl"} fontWeight={"extrabold"}>
                          $
                          {isMonthly
                            ? plan.price.monthlyDiscounted
                            : plan.price.yearlyDiscounted}
                        </Text>
                        <Text fontSize={"sm"} fontStyle={"italic"} ml={"5px"}>
                          /month
                        </Text>
                      </Box>
                      {!isMonthly && plan.name !== "free" && (
                        <Text
                          fontStyle={"italic"}
                          fontSize={"smaller"}
                          color={plan.name === "advanced" ? "" : "#4D5461"}
                        >
                          billed as ${plan.price.yearlyTotal} per year
                        </Text>
                      )}
                    </Box>

                    <Text fontSize={"xl"} fontWeight={"bold"}>
                      {plan.title}
                    </Text>

                    <Text fontSize={"smaller"}>{plan.summary}</Text>

                    <Box display={"flex"} flexDirection={"column"} gap={"8px"}>
                      {plan.optionsList.map((option) => {
                        const [firstWord, secondWord, ...rest] =
                          option.split(" ");

                        return (
                          <Box
                            display="flex"
                            gap="5px"
                            alignItems={"start"}
                            className="option-item"
                            fontSize={"smaller"}
                          >
                            <Text fontWeight={"light"}>
                              <Text fontWeight={"semibold"}>
                                {firstWord} {secondWord}
                              </Text>{" "}
                              {rest.join(" ")}
                            </Text>
                          </Box>
                        );
                      })}
                    </Box>

                    <Button
                      colorScheme={
                        plan.name ===
                        (subscriptionData?.formattedSubscriptionPlan || "free")
                          ? "whiteAlpha"
                          : "blue"
                      }
                      w={"100%"}
                      h={"56px"}
                      minH={"56px"}
                      backgroundColor={
                        plan.name !==
                        (subscriptionData?.formattedSubscriptionPlan || "free")
                          ? "#0A64BC"
                          : "white"
                      }
                      color={
                        plan.name !==
                        (subscriptionData?.formattedSubscriptionPlan || "free")
                          ? "#F9FAFB"
                          : "#0564B8"
                      }
                      borderRadius={"8px"}
                      fontSize={"16px"}
                      lineHeight={"24px"}
                      fontWeight={600}
                      fontFamily={"Poppins"}
                      onClick={() => getCheckoutUrl('1',plan.name)}
                    >
                      {loading === idx ? (
                        <Spinner mr="5px" />
                      ) : (
                        <>
                          {plan.name ===
                          (subscriptionData?.formattedSubscriptionPlan ||
                            "free")
                            ? "Current Plan"
                            : subPriority.indexOf(plan.name) <
                              subPriority.indexOf(
                                subscriptionData?.formattedSubscriptionPlan ||
                                  "free"
                              )
                            ? "Downgrade Plan"
                            : "Upgrade to Plan"}
                        </>
                      )}
                    </Button>
                  </Box>
                </TabPanel>
              );
            })}
          </TabPanels>
        </Tabs>
      </Box>
    </Box>
  );
}
