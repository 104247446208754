import { CustomNegativeChartData, CustomNegativeChartDataProps } from "./CustomNegativeChart/negativeChartTypes"
import { SUPPORTED_QUARTERS } from "./CustomNegativeChart/negativeChartUtils"

export function useCustomChart(data: CustomNegativeChartDataProps[]){
    const validateData = (initialData: CustomNegativeChartDataProps[]): CustomNegativeChartDataProps[] => {
        const results: CustomNegativeChartDataProps[] = []
        initialData.forEach(item => {
            const isDuplicated = initialData.filter(i => i.date === item.date && i.period === item.period)
            const inResults = results.filter(i => i.date === item.date && i.period === item.period)
            if(isDuplicated.length > 1 && inResults.length === 0){
                results.push(isDuplicated[0])
            }else if(isDuplicated.length === 1 && inResults.length === 0){
                results.push(item)
            }
        })
    
        return results
    }
    
    const parserData = (data: CustomNegativeChartDataProps[]): CustomNegativeChartData => {
        const parsed: CustomNegativeChartData = {
            years: []
        }
    
        data.forEach((item: any) => {
            if(!parsed.years.some(i => i.year === item.date)){
                parsed.years.push({ year: item.date, quarters: data.filter(i => i.date === item.date).map(i => {
                    return { quarter: i.period, value: i.percentile }
                }) })
            }
        })
    
        return parsed
    }
    
    const fillData = (data: CustomNegativeChartData) => {
        data.years.forEach(item => {
            if(item.quarters.length < 4){
                SUPPORTED_QUARTERS.forEach(quarter => {
                    if(!item.quarters.some(i => i.quarter === quarter)){
                        item.quarters.push({
                            quarter: quarter,
                            value: 0
                        })
                    }
                })
            }
        })
    
        return data
    }
    
    const sortData = (data: CustomNegativeChartData) => {
        data.years.forEach(item => {
            item.quarters.sort((a, b) => {
                return Number.parseInt(a.quarter.slice(1)) - Number.parseInt(b.quarter.slice(1))
            })
        })
    
        data.years.sort((a, b) => {
            return Number.parseInt(a.year) - Number.parseInt(b.year)
        })
    
        return data
    }
    
    const getData = (initialData: CustomNegativeChartDataProps[]) => {
        const validated = validateData(initialData)
        const parsed = parserData(validated)
        const filled = fillData(parsed)
        const sorted = sortData(filled)
        return sorted
    }

    return getData(data)
}