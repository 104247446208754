import {
  Flex,
  useDisclosure,
  useMediaQuery,
  Text,
  Box,
} from "@chakra-ui/react";
import { TextEditable } from "../common/Text/TextEditable";
import { DeleteModal } from "../Modals/DeleteModal";
import { ReactComponent as SaveIcon } from "../../assets/svg/baselineSave.svg";
import { textSmallProps } from "../../theme/theme";
interface Props {
  name: string;
  onClickSaveButton?: (value: string) => void;
  onSaveName?: (newValue: string) => void;
  onDelete?: () => void;
}
export function ActionsScreener({
  name,
  onClickSaveButton,
  onSaveName,
  onDelete,
}: Props) {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");

  return (
    <>
      {
        <Flex
          w="max-content"
          flexDirection="row"
          alignItems="start"
          backgroundColor="#FFF"
          flexGrow="2"
          fontFamily={"Poppins"}
          my={'15px'}
          mx={0}
        >
          <TextEditable
            small={!isLargerThan768}
            defaultValue={name}
            onChange={(nextValue) => {
              onSaveName && onSaveName(nextValue);
            }}
            onDelete={() => {
              onOpen();
            }}
          ></TextEditable>
          <Box
            onClick={(e) => {
              e.preventDefault();
              onClickSaveButton && onClickSaveButton(name);
            }}
            cursor="pointer"
            w={"170px"}
            h={"44px"}
            alignItems={"center"}
            justifyContent={"space-between"}
            display={"flex"}
            borderRadius={"20px !important"}
            padding={"10px !important"}
            gap={"10px !important"}
            bgColor={"#F9FAFB"}
          >
            <Text
              ml={"15px"}
              {...textSmallProps}
              color={"#085096"}
              fontWeight={"600"}
              fontSize="12px"
              lineHeight={"24px"}
            >
              Save changes
            </Text>
            <Box w={"24px"} h={"24px"} mr={"10px"}>
              <SaveIcon color={"#FFFFFF"} />
            </Box>
          </Box>
        </Flex>
      }
      <DeleteModal
        isOpen={isOpen}
        suffix="screener"
        onClose={onClose}
        name={name}
        onDelete={() => {
          onDelete && onDelete();
          onClose();
        }}
      />
    </>
  );
}
