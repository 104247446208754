import { Box, Text, useDisclosure, useMediaQuery } from "@chakra-ui/react";
import { useEffect, useState, useContext } from "react";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as InactiveIcon } from '../assets/svg/inactivelist.svg';
import { ReactComponent as ListIcon } from '../assets/svg/list.svg';
import { ReactComponent as FilterIcon } from '../assets/svg/filterAlt.svg';
import { CreateScreenerModal } from "../components/Modals/CreateScreener";
import Portal from "../components/Portal";
import { CommonButton } from "../components/common/buttons/common";
import { CommonMenu } from "../components/common/menu/WatchlistMenu";
import DesktopContent from "../layout/MainContent/DesktopContent";
import MobileContent from "../layout/MainContent/MobileContent";
import { useFetchScreenerDataQuery } from "../services/screenerApi";
import { LeftCommonSubView } from "../subviews/LeftSubView/Watchlist/index";
import { RightSubView } from "../subviews/RightSubview/Watchlist/index";
import { IMenu } from "../types/common";
import { ScreenerOverview } from "../subviews/RightSubview/Screener/overview";
import { NoneScreener } from "../components/Box/None";
import {EmitterContext} from "../context/emiter"
export function Screener() {
  const emmiter = useContext(EmitterContext)
  const [isLargerThan768] = useMediaQuery('(min-width: 768px)')
  const [activeIdx, setActiveIdx] = useState(0);
  const [menu, setMenu] = useState<Array<IMenu>>([]);
  const { id } = useParams();
  const navigate = useNavigate();
  const { isOpen, onClose, onOpen } = useDisclosure()
  const location = useLocation();

  const { isFetching, isError, data = [], refetch } = useFetchScreenerDataQuery({
    refetchOnMountOrArgChange: true
  })

  useEffect(() => {

    if (location.state != null) {
      if (location.state.isDeleting) {
        refetch().unwrap().then((d) => {
          if (d.length > 0) {
            navigate(`/screener/${d[0]?.id}`, { replace: true, state: null })
          }
        })
      }

    }

  }, [location, navigate, refetch]);

  useEffect(() => {

    const newMenu = data.map((item) => {
      return {
        name: item.name,
        code: item.id,
        icon: () => <ListIcon color="inherit" />
      }
    })

    if (data.length > 0 && location.state == null && location.pathname === "/screener") {
      navigate(`/screener/${data[0]?.id}`, { replace: true })
      setActiveIdx(0)
    }

    setMenu(newMenu)
    if (id !== undefined) {
      const index = newMenu.findIndex((m) => m.code?.includes(id))
      if (index !== -1) {
        setActiveIdx(index)
      }
    }
    emmiter.emit("save", "newMenu")
  }, [data, id, location.pathname, location.state, navigate]);

  useEffect(() => {
    emmiter.on("whenChangeFilter", async () => {})
  },[])


  const icon = () => <FilterIcon width="52px" height="55px" />

  return (
    <>

      {
        !isLargerThan768 && (
          <Portal target='header-app'>
            <Box w='100%' padding={2} mt={'-40px'} height={'auto'} display={'flex'} flexDirection={'column'} alignItems={'start'} transition={'1s all'}>
              <Box textAlign={'center'} w='100%' display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                <Text
                  fontSize='32px'
                  fontWeight='500'
                  lineHeight='35px'
                  color='#fff'
                  textAlign='center'
                >
                  {"Screener"}
                </Text>
                <CommonButton
                  title="Add +"
                  customProps={
                    {
                      size: 'sm',
                      width: '80px',
                      height: '40px',
                      padding: '10px, 15px, 10px, 15px',
                      onClick: onOpen,
                      borderRadius: '20px'
                    }
                  }
                />
              </Box>
              <CommonMenu
                menuLoading={isFetching || isError}
                currentIdx={activeIdx}
                sectionName="SCREENER"
                onSelectMenu={(idx, menu) => {
                  setActiveIdx(idx)
                  navigate(`/screener/${menu.code}`, { replace: true, relative: 'path' })
                }}
                menu={menu}
              />
            </Box>
          </Portal>
        )
      }
      <MobileContent>
        <DesktopContent>
          {
            <LeftCommonSubView
              menuLoading={isFetching || isError}
              currentIdx={activeIdx}
              sectionName={"SCREENER"}
              onSelectMenu={(idx, menu) => {
                setActiveIdx(idx)
                navigate(`/screener/${menu.code}`, { replace: true, relative: 'path' })
              }}
              icon={icon}
              menu={menu}
              inactiveIcon={<InactiveIcon />}
            >
              <CommonButton
                title={`Add screener + `}
                customProps={
                  {
                    onClick: onOpen,
                    width: 'auto !important',
                    height: '44px !important',
                    borderRadius: '15px !important',
                    padding: '10px, 20px, 10px, 20px',
                    gap: '10px',
                  }
                }
                textProps={
                  {
                    fontSize: '12px',
                    fontWeight: '600',
                    lineHeight: '24px'
                  }
                }
              />
            </LeftCommonSubView>
          }

          <RightSubView>
            {
              id && <ScreenerOverview
                id={id}
                onEditName={() => refetch()}
              />
            }
            {
              (!isFetching && !isError && id === undefined && data.length === 0) && <NoneScreener onClick={onOpen} />
            }
          </RightSubView>

        </DesktopContent>
      </MobileContent>

      <CreateScreenerModal
        isOpen={isOpen}
        onClose={onClose}
        onSaveSuccess={() => refetch()}
      ></CreateScreenerModal>
    </>
  );
}