import { actualScore } from "../store/slices/stock";
import { ScoreTimeline, ScoresTimeline } from "../types/Chart";
import { useSelector } from "react-redux";

export enum SupportedChartOption {
  monkScoreTimeline = "monkScoreTimeline",
  valuationScoreTimeline = "valuationScoreTimeline",
  growtScoreTimeline = "growtScoreTimeline",
  profitabilityScoreTimeline = "profitabilityScoreTimeline",
  healthScoreTimeline = "healthScoreTimeline",
  shareholderScoreTimeline = "shareholderScoreTimeline",
}

export function useDataChart(option?: SupportedChartOption) {
  const data = useSelector(actualScore);

  const getChartDataByOption = () => {
    if(!option) return [];

    switch (option) {
      case SupportedChartOption.monkScoreTimeline:
        return data?.monkScoreTimeline || [];
      case SupportedChartOption.valuationScoreTimeline:
        return data?.valuationScoreTimeline || [];
      case SupportedChartOption.growtScoreTimeline:
        return data?.growtScoreTimeline || [];
      case SupportedChartOption.profitabilityScoreTimeline:
        return data?.profitabilityScoreTimeline || [];
      case SupportedChartOption.healthScoreTimeline:
        return data?.healthScoreTimeline || [];
      case SupportedChartOption.shareholderScoreTimeline:
        return data?.shareholderScoreTimeline || [];
      default:
        return [];
    }
  };

  const sortedFn = (a: ScoreTimeline, b: ScoreTimeline): number => {
    const firstDate = a.statementsDate
      ? new Date(a.statementsDate).getTime()
      : 0;
    const secondDate = b.statementsDate
      ? new Date(b.statementsDate).getTime()
      : 0;
    if (firstDate === secondDate) {
      if (a.year === b.year) {
        if (a.period === b.period) {
          const firstProcessedDate = a.processedAsOfDate
            ? new Date(a.processedAsOfDate).getTime()
            : 0;
          const secondProcessedDate = b.processedAsOfDate
            ? new Date(b.processedAsOfDate).getTime()
            : 0;
          return firstProcessedDate - secondProcessedDate;
        }
        return a.period.localeCompare(b.period);
      }
      return Number.parseInt(a.year) - Number.parseInt(b.year);
    }
    return firstDate - secondDate;
  };

  const customFilter = (data: ScoreTimeline[]) => {
    const recordInFalse: ScoreTimeline | undefined = data.find(
      (item) => !item.isQuarterlyResult
    );
    if (!recordInFalse) {
      return data;
    }

    const itemsToRemove = data.filter(
      (item) =>
        item.period === recordInFalse.period &&
        item.year === recordInFalse.year &&
        item.isQuarterlyResult
    );

    if (itemsToRemove.length === 0) {
      return data;
    }

    return data.filter((item: ScoreTimeline) => !itemsToRemove.includes(item));
  };

  const mockDataForCharts = (array: ScoreTimeline[]) =>
    array.length > 0 ? customFilter(array.sort(sortedFn)) : [];

  const getAllChartsSanitizedData = () => {
    return {
      monkScoreTimeline: data ? mockDataForCharts([...data.monkScoreTimeline]) : [],
      valuationScoreTimeline: data ? mockDataForCharts([
        ...data.valuationScoreTimeline,
      ]) : [],
      growtScoreTimeline: data ? mockDataForCharts([...data.growtScoreTimeline]) : [],
      profitabilityScoreTimeline: data ? mockDataForCharts([
        ...data.profitabilityScoreTimeline,
      ]) : [],
      healthScoreTimeline: data ? mockDataForCharts([...data.healthScoreTimeline]) : [],
      shareholderScoreTimeline: data ? mockDataForCharts([
        ...data.shareholderScoreTimeline,
      ]) : []
    };
  };

  const getChartSanitizedData = () => data ? mockDataForCharts([...getChartDataByOption()]) : [];

  return { data, getChartDataByOption, getChartSanitizedData, getAllChartsSanitizedData };
}
