import { Box, Text, useDisclosure, useMediaQuery } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as EyeIcon } from '../assets/svg/EyeshowWhite.svg';
import { ReactComponent as InactiveIcon } from '../assets/svg/inactivelist.svg';
import { ReactComponent as ListIcon } from '../assets/svg/list.svg';
import { ReactComponent as CaseIcon } from '../assets/svg/solar-case.svg';
import { NoneWatchlist } from "../components/Box/None";
import { CreateWatchlistModal } from "../components/Modals/CreateWatchlist";
import { CommonButton } from "../components/common/buttons/common";
import { CommonMenu } from "../components/common/menu/WatchlistMenu";
import DesktopContent from "../layout/MainContent/DesktopContent";
import MobileContent from "../layout/MainContent/MobileContent";
import { useFetchWatchlistDataQuery } from "../services/watchlistPortfolioApi";
import { LeftCommonSubView } from "../subviews/LeftSubView/Watchlist/index";
import { RightSubView } from "../subviews/RightSubview/Watchlist/index";
import { WatchlistOverview } from "../subviews/RightSubview/Watchlist/overview";
import { IMenu } from "../types/common";
import { useLocation } from 'react-router-dom';
import Portal from "../components/Portal";

type WatchListProps = {
  is?: 'watchlist' | 'portfolio'
}
export function WatchList({ is = 'watchlist' }: WatchListProps) {

  const [isLargerThan768] = useMediaQuery('(min-width: 768px)')
  const [activeIdx, setActiveIdx] = useState(0);
  const [menu, setMenu] = useState<Array<IMenu>>([]);
  const { id } = useParams();
  const navigate = useNavigate();
  const { isOpen, onClose, onOpen } = useDisclosure()
  const location = useLocation();

  const { isFetching, isError, data = [], refetch } = useFetchWatchlistDataQuery(is, {
    refetchOnMountOrArgChange: true
  })

  useEffect(() => {

    if (location.pathname === `/${is}` && location.state != null) {
      if (location.state.isDeleting) {
        refetch().unwrap().then((d) => {
          if(d.length > 0){
            navigate(`/${is}/${d[0]?.id}`, { replace: true, state: null })
          }
        })
      }

    }

  }, [is, location, refetch]);

  useEffect(() => {

    const newMenu = data.map((item) => {
      return {
        name: item.name,
        code: item.id,
        icon: () => <ListIcon color="inherit" />
      }
    })

    if (data.length > 0 && location.state == null && location.pathname === `/${is}`) {
      navigate(`/${is}/${data[0]?.id}`, { replace: true })
      setActiveIdx(0)
    }

    setMenu(newMenu)
    if (id !== undefined) {
      const index = newMenu.findIndex((m) => m.code?.includes(id))
      if (index !== -1) {
        setActiveIdx(index)
      }
    }
  }, [data, id, is, location.pathname, location.state, navigate]);


  const icon = () => is === 'watchlist' ? <EyeIcon width="70px" height="70px" /> : <CaseIcon width="70px" height="70px" />;

  return (
    <>
  
    {
          !isLargerThan768 && (
            <Portal target='header-app'>
              <Box w='100%' padding={2} mt={'-40px'} height={'auto'} display={'flex'} flexDirection={'column'} alignItems={'start'} transition={'1s all'}>
              <Box textAlign={'center'} w='100%' display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                <Text
                  fontSize='32px'
                  fontWeight='500'
                  lineHeight='35px'
                  color='#fff'
                  textAlign='center'
                >
                  {is.charAt(0).toUpperCase() + is.slice(1)}
                </Text>
                <CommonButton
                  title="Add +"
                  customProps={
                    {
                      size: 'sm',
                      width: '80px',
                      height: '40px',
                      padding: '10px, 15px, 10px, 15px',
                      onClick: onOpen,
                      borderRadius: '15px'
                    }
                  }
                />
              </Box>
              <CommonMenu
                menuLoading={isFetching || isError}
                currentIdx={activeIdx}
                sectionName={is.toUpperCase()}
                onSelectMenu={(idx, menu) => {
                  setActiveIdx(idx)
                  navigate(`/${is}/${menu.code}`, { replace: true, relative: 'path' })
                }}
                menu={menu}
              />
            </Box>
            </Portal>
          )
        }
      <MobileContent>
        <DesktopContent>
          {
            <LeftCommonSubView
              menuLoading={isFetching || isError}
              currentIdx={activeIdx}
              sectionName={is.toUpperCase()}
              onSelectMenu={(idx, menu) => {
                setActiveIdx(idx)
                navigate(`/${is}/${menu.code}`, { replace: true, relative: 'path' })
              }}
              icon={icon}
              menu={menu}
              inactiveIcon={<InactiveIcon />}
            >
              <CommonButton
                title={`Add ${is} + `}
                customProps={
                  {
                    onClick: onOpen,
                    width: 'auto !important',
                    height: '44px !important',
                    borderRadius: '15px !important',
                    padding: '10px, 20px, 10px, 20px',
                    gap: '10px',
                  }
                }
                textProps={
                  {
                      fontSize: '12px',
                  fontWeight: '600',
                  lineHeight: '24px'
                  }
              } 
              />
            </LeftCommonSubView>
          }

          <RightSubView>
            {

              id ? (

                <WatchlistOverview
                  name={menu[activeIdx]?.name}
                  is={is}
                  id={id}
                  onEditName={() => refetch()}
                />


              ) : (
                (!isFetching && !isError && id === undefined && data.length === 0) && <NoneWatchlist is={is} onClick={onOpen} />
              )
            }
          </RightSubView>

        </DesktopContent>
      </MobileContent>

      <CreateWatchlistModal
        is={is}
        isOpen={isOpen}
        onClose={onClose}
        onSaveSuccess={() => refetch()}
      ></CreateWatchlistModal>
    </>
  );
}